export enum BlockCategory {
	Wool = 0,
	Wood = 1,
	Stone = 2,
	Ore = 3,
	Clay = 4,
	Concrete = 5,
	Natural = 6,
	Crafted = 7,
	Desert = 8,
	Nether = 9,
	End = 10,
	Ocean = 11,
	Creative = 12,
	SpeedCube = 13,
}