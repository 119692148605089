import { ImageUtils } from '@/logic/Shared/ImageUtils';
import { ResizeObserver } from 'resize-observer';

export class ZoomableCanvasHelper {
	private canvas: HTMLCanvasElement;
	private context: CanvasRenderingContext2D;
	private canvasDiv: HTMLDivElement;
	private image!: HTMLImageElement;

	private autoSize: boolean;
	private displayImageX!: number;
	private displayImageY!: number;
	private displayImageWidth!: number;
	private displayImageHeight!: number;

	private readonly ZOOM_FACTOR = 1.15;
	private readonly MAX_ZOOM_FACTOR = 10;

	private lastX!: number;
	private lastY!: number;

	private mouseDown: boolean;

	public constructor(canvas: HTMLCanvasElement, canvasDiv: HTMLDivElement, window: Window) {
		this.canvas = canvas;
		this.context = this.canvas.getContext("2d")!;
		this.canvasDiv = canvasDiv;

		this.autoSize = true;
		this.mouseDown = false;

		const outer = this;
		canvas.addEventListener("wheel", function (evt: WheelEvent) { outer.handleScroll(evt) }, false);
		canvas.addEventListener("mousedown", function (evt: MouseEvent) { outer.handleMouseDown(evt) }, false);
		canvas.addEventListener("mouseup", function () { outer.handleMouseUp() }, false);
		canvas.addEventListener("mousemove", function (evt: MouseEvent) { outer.handleMouseMove(evt) }, false);
		canvas.addEventListener("mouseleave", function () { outer.handleMouseUp() }, false);

		// Old way (which mostly works, except when other parts of the page resize themselves instead of the whole window resizing):
		//window.addEventListener("resize", function () { outer.resizeAndRedraw() }, false);

		// New way, which is now supported in the vast majority of browsers people use:
		new ResizeObserver(function() { outer.resizeAndRedraw(); }).observe(this.canvasDiv);
	}

	private handleScroll(evt: WheelEvent): void {
		if (this.image !== null) {
			this.zoom(evt.offsetX, evt.offsetY, evt.deltaY);
			evt.preventDefault();
		}
	}

	private handleMouseDown(evt: MouseEvent): void {
		this.mouseDown = true;
		this.lastX = evt.x;
		this.lastY = evt.y;
	}

	private handleMouseUp(): void {
		this.mouseDown = false;
	}

	private handleMouseMove(evt: MouseEvent): void {
		if (this.mouseDown) {
			this.pan(this.lastX, this.lastY, evt.x, evt.y);
			this.lastX = evt.x;
			this.lastY = evt.y;
		}
	}

	private zoom(x: number, y: number, zoomAmount: number) : void {
		let toScaleBy = Math.pow(this.ZOOM_FACTOR, (-zoomAmount) / 100);

		// Cap so we don't zoom too far in or too far out:
		this.autoSize = false;
		const maxScale = Math.max(this.MAX_ZOOM_FACTOR, this.getAutoFitScale());
		if (((toScaleBy * this.displayImageWidth) / this.image.width) > maxScale) {
			toScaleBy = (maxScale * this.image.width) / this.displayImageWidth;
			this.autoSize = (maxScale === this.getAutoFitScale());
		}
		const minScale = this.getAutoFitScale();
		if (((toScaleBy * this.displayImageWidth) / this.image.width) < minScale) {
			toScaleBy = (minScale * this.image.width) / this.displayImageWidth;
			this.autoSize = true;
		}

		// If we zoom out to the point where it could auto fit, just go ahead and switch to that
		if (this.autoSize) {
			this.autoFixSize();
		} else {
			this.displayImageX = x - (toScaleBy * (x - this.displayImageX));
			this.displayImageY = y - (toScaleBy * (y - this.displayImageY));
			this.displayImageWidth = toScaleBy * this.displayImageWidth;
			this.displayImageHeight = toScaleBy * this.displayImageHeight;
		}

		this.redraw();
	}

	private pan(fromX: number, fromY: number, toX: number, toY: number): void {
		this.displayImageX = this.displayImageX + toX - fromX;
		this.displayImageY = this.displayImageY + toY - fromY;

		this.autoSize = false;
		this.redraw();
	}

	public setImage(image: HTMLImageElement) {
		this.image = image;
		this.autoSize = true;
		this.resizeAndRedraw();
	}

	public resizeAndRedraw() {
		this.resizeCanvas();
		if (this.autoSize) {
			this.autoFixSize();
		} else {
			// If the new size constraints have changed something, we may have to zoom out or in?
			const minScale = this.getAutoFitScale();
			if ((this.displayImageWidth / this.image.width) < minScale) {
				this.autoSize = true;
				this.autoFixSize();
			}
		}
		this.redraw();
	}

	private autoFixSize() {
		if (!this.image) {
			return;
		}
		const scale = this.getAutoFitScale();
		this.displayImageWidth = this.image.width * scale;
		this.displayImageHeight = this.image.height * scale;
		this.displayImageX = (this.canvas.clientWidth - this.displayImageWidth) / 2;
		this.displayImageY = (this.canvas.clientHeight - this.displayImageHeight) / 2;
	}

	private getAutoFitScale(): number {
		if ((this.image.width / this.image.height) > (this.canvas.clientWidth / this.canvas.clientHeight)) {
			return this.canvas.clientWidth / this.image.width;
		}
		else {
			return this.canvas.clientHeight / this.image.height;
		}
	}

	private redraw(): void {
		// Wipe the canvas:
		this.drawBackground();

		// Draw the image:
		if (this.image) {
			this.context.imageSmoothingEnabled = (this.image.width > this.displayImageWidth);
			this.context.imageSmoothingQuality = "high";
			// Seems like we ought to round here, but the canvas seems to just handle it so I guess it's fine:
			this.context.drawImage(this.image, this.displayImageX, this.displayImageY, this.displayImageWidth, this.displayImageHeight);
		}
	}

	private drawBackground(): void {
		const patternCanvas = document.createElement('canvas');
		const patternContext = patternCanvas.getContext('2d')!;

		const size = 22;
		const halfSize = size / 2;
		
		patternCanvas.width = size;
		patternCanvas.height = size;

		patternContext.fillStyle = "#eeeeee";
		patternContext.fillRect(0, 0, halfSize, halfSize);
		patternContext.fillRect(halfSize, halfSize, halfSize, halfSize);

		patternContext.fillStyle = "white";
		patternContext.fillRect(0, halfSize, halfSize, halfSize);
		patternContext.fillRect(halfSize, 0, halfSize, halfSize);
		
		const pattern = this.context.createPattern(patternCanvas, 'repeat')!;
		this.context.fillStyle = pattern;
		this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
	}

	public toDataURL(): string {
		return ImageUtils.convertImageDataToImageSrc(ImageUtils.convertImageToImageData(this.image));
	}

	private resizeCanvas() {
		this.canvas.width = this.canvasDiv.clientWidth;
		this.canvas.height = this.canvasDiv.clientHeight;
	}
}