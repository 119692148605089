import { BlockType } from "./BlockType";
import { BlockCategory } from "./BlockCategory";
import { BlockFace } from "./BlockFace";
import { BlockOrientationMode } from "./BlockOrientationMode";

export class Block {
	public name: string;
	public blockType: BlockType;
	public blockCategory: BlockCategory;
	public sideImageName: string;
	public topImageName: string;
	public bottomImageName: string;
	public sideFaces: BlockFace[];
	public topFaces: BlockFace[];
	public bottomFaces: BlockFace[];
	public isActiveByDefault: boolean;
	public isActive: boolean;

	public constructor(blockType: BlockType, blockCategory: BlockCategory, name: string, sideImageName: string, topImageName: string, bottomImageName: string, isActiveByDefault: boolean, sideImages: HTMLImageElement[], topImages: HTMLImageElement[], bottomImages: HTMLImageElement[]) {
		this.blockType = blockType;
		this.blockCategory = blockCategory;
		this.name = name;
		this.sideImageName = sideImageName;
		this.topImageName = topImageName;
		this.bottomImageName = bottomImageName;
		this.isActiveByDefault = isActiveByDefault;
		this.isActive = isActiveByDefault;
		
		this.sideFaces = [];
		for (const image of sideImages) {
			this.sideFaces.push(new BlockFace(image));
		}
		
		// Note: topImages and bottomImages should never be blank or null, but they may be a copy of the side images (that logic is handled by the caller)
		
		this.topFaces = [];
		for (const image of topImages) {
			this.topFaces.push(new BlockFace(image));
		}
		
		this.bottomFaces = [];
		for (const image of bottomImages) {
			this.bottomFaces.push(new BlockFace(image));
		}
	}

	public getFaces(mode: BlockOrientationMode): BlockFace[] {
		if (mode === BlockOrientationMode.Bottom) {
			return this.bottomFaces;
		} else if (mode === BlockOrientationMode.Top) {
			return this.topFaces;
		} else {
			return this.sideFaces;
		}
	}
}