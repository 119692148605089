

import { Vue, Options } from 'vue-class-component';
import { Block } from '@/logic/Spritecraft/Block';
import { BlocksForCategory } from '@/logic/Spritecraft/BlocksForCategory';
import { BlockCategory } from '@/logic/Spritecraft/BlockCategory';
import { ImageProcessor } from "@/logic/Spritecraft/ImageProcessor";
import { ZoomableCanvasHelper } from "@/logic/Spritecraft/ZoomableCanvasHelper";
import download from "downloadjs";
import GoogleAd from '@/components/GoogleAd.vue';
import DonateArea from '@/components/Spritecraft/DonateArea.vue';
import { GoogleAdSlotValues } from "@/logic/GoogleAdSlotValues";
import { DitherMode } from '@/logic/Spritecraft/DitherMode';
import { Settings } from '@/logic/Spritecraft/Settings';
import { BlockOrientationMode } from '@/logic/Spritecraft/BlockOrientationMode';
import BlocksEditor from '@/components/Spritecraft/BlocksEditor.vue';

@Options({
  components: {
    GoogleAd,
    BlocksEditor,
    DonateArea,
  },
})
export default class Spritecraft extends Vue {
    imageProcessor!: ImageProcessor;
    settings = new Settings();
    outputCanvasHelper!: ZoomableCanvasHelper;
    blocks: Block[] = [];
    processImageCallback!: () => void;
    showSamples = true;

    previousBlockSelections: boolean[] = [];
    previousSettings: Settings = new Settings();
    previousImageSource: any = "";

    starterImage1 = require("@/assets/images/spritecraft/samples/crono.png");
    starterImage2 = require("@/assets/images/spritecraft/samples/pennysprite.png");
    starterImage3 = require("@/assets/images/spritecraft/samples/raja.png");
    starterImage4 = require("@/assets/images/spritecraft/samples/orcroadtrip.png");
    
    selectedSampleSource = this.starterImage1;
    customImageSource = "";
    selectedSampleImage: HTMLImageElement | null = null;
    customImage: HTMLImageElement | null = null;

    get currentImageSource() {
        if (this.showSamples) {
            return this.selectedSampleSource;
        } else {
            return this.customImageSource;
        }
    }

    get currentImage() {
        if (this.showSamples) {
            return this.selectedSampleImage;
        } else {
            return this.customImage;
        }
    }

    get image1Selected() {
        return this.currentImageSource === this.starterImage1;
    }

    get image2Selected() {
        return this.currentImageSource === this.starterImage2;
    }

    get image3Selected() {
        return this.currentImageSource === this.starterImage3;
    }

    get image4Selected() {
        return this.currentImageSource === this.starterImage4;
    }

    get ditherModeAuto() {
        return DitherMode.Auto;
    }

    get ditherModeOn() {
        return DitherMode.On;
    }

    get ditherModeOff() {
        return DitherMode.Off;
    }

    get blockFaceSide() {
        return BlockOrientationMode.Side;
    }

    get blockFaceTop() {
        return BlockOrientationMode.Top;
    }

    get blockFaceBottom() {
        return BlockOrientationMode.Bottom;
    }

    get hasChanges() {
        const currBlockSelections = this.blockSelections;
        if (currBlockSelections.length !== this.previousBlockSelections.length){
            return true;
        }
        for (let i in currBlockSelections) {
            if (currBlockSelections[i] !== this.previousBlockSelections[i]) {
                return true;
            }
        }

        if (!this.previousSettings.areSame(this.settings)) {
            return true;
        }

        if (this.currentImageSource !== this.previousImageSource) {
            return true;
        }

        return false;
    }

    get blockSelections() {
        const blockSelections: boolean[] = [];
        for (const block of this.blocks) {
            blockSelections.push(block.isActive);
        }
        return blockSelections;
    }

    get blocksByCategory() {
        const byCategory: BlocksForCategory[] = [];
        for (const block of this.blocks) {
            if (!byCategory[block.blockCategory]) {
                byCategory[block.blockCategory] = new BlocksForCategory(this.getCategoryName(block.blockCategory), []);
            }
            byCategory[block.blockCategory].blocks.push(block);
        }
        return byCategory;
    }

    get canGenerateImage(): boolean {
        // Using a custom image but haven't selected one yet:
        if (!this.showSamples && this.customImageSource === "") {
            return false;
        }

        if (this.currentImage === null) {
            return false;
        }

        for (const block of this.blocks) {
            if (block.isActive) {
                return true;
            }
        }
        // No active blocks, so image can't be generated:
        return false;
    }

    get adSlot() {
        return GoogleAdSlotValues.SPRITECRAFTWEB_AD;
    }

    mounted() {
        const outputImageCanvas = document.getElementById("outputImageCanvas") as HTMLCanvasElement;
        const outputCanvasDiv = document.getElementById("outputCanvasDiv") as HTMLDivElement;
        const fileInput = document.getElementById("fileInput") as HTMLInputElement;
        const fileInput2 = document.getElementById("fileInput2") as HTMLInputElement;
        const saveImageButton = document.getElementById("saveImageButton") as HTMLButtonElement;

        // Note: it is actually _really important_ that, for performance reasons, this gets created here and not were the variable is declared. If
        // it's created right away, Vue's reactive framework picks it up and everything slows down as I'm guessing it pays attention to every property
        // change inside the object:
        this.imageProcessor = new ImageProcessor(this.settings, this.loadingFinished);
        this.outputCanvasHelper = new ZoomableCanvasHelper(outputImageCanvas, outputCanvasDiv, window);

        fileInput.addEventListener("change", this.handleFileSelect, false);
        fileInput2.addEventListener("change", this.handleFileSelect, false);

        const self = this;
        saveImageButton.addEventListener("click", function (ev) {
            // Uses downloadjs:
            download(self.outputCanvasHelper.toDataURL(), "SpritecraftOutput.png", "");
        });

        this.processImageCallback = this.processImage;

        const loadedBlocks = this.imageProcessor.getAllBlocks();
        for (const block of loadedBlocks) {
            this.blocks.push(block);
        }
    }

    setShowSamples(show: boolean) {
        this.showSamples = show;
    }
    
    processImageBleh() {
        if (this.processImageCallback) {
            this.processImageCallback();
        }
    }

    getCategoryName(blockCategory: BlockCategory) {
        // See BlockCategory.ts
        // (this really should be better ... there are ways to use Typescript with Vue, and we could reference the TypeScript business logic
        // code in some better way and move this type of logic inside of it)
        if (blockCategory === 0) {
            return "Wool";
        } else if (blockCategory === 1) {
            return "Wood";
        } else if (blockCategory === 2) {
            return "Stone";
        } else if (blockCategory === 3) {
            return "Ore";
        } else if (blockCategory === 4) {
            return "Clay";
        } else if (blockCategory === 5) {
            return "Concrete";
        } else if (blockCategory === 6) {
            return "Natural";
        } else if (blockCategory === 7) {
            return "Crafted";
        } else if (blockCategory === 8) {
            return "Desert";
        } else if (blockCategory === 9) {
            return "Nether";
        } else if (blockCategory === 10) {
            return "The End";
        } else if (blockCategory === 11) {
            return "Ocean";
        } else if (blockCategory === 12) {
            return "Creative ONLY!";
        } else if (blockCategory === BlockCategory.SpeedCube) {
            return "Speed Cube";
        }
        return "ZONO AN ERROR!";
    }

    loadingFinished(error: string | null) {
        if (error === null) {
            this.setImageFromSource(this.starterImage1, false, true);
        } else {
            this.showError(error);
        }
    }

    dragDropFile(dragEvent: DragEvent) {
        this.setCustomImage(dragEvent.dataTransfer?.files);
    }

    handleFileSelect(evt: Event) {
        this.setCustomImage((evt.target as HTMLInputElement).files);
    }

    setCustomImage(files: FileList | undefined | null) {
        if (files && files.length > 0) {
            this.showSamples = false;
            const fileReader = new FileReader();
            const self = this;
            fileReader.onload = function () { self.setImageFromSource("data:image/png;base64," + window.btoa(fileReader.result as string), true); }
            fileReader.readAsBinaryString(files[0]);
        }
    }

    setImageFromSource(source: any, isCustomImage = false, processAfterLoad = false) {
        if (!source) { return; }

        const image = new Image();
        const self = this;
        if (isCustomImage) {
            this.customImageSource = source;
        } else {
            this.selectedSampleSource = source;
        }
        image.onload = function () { self.imageLoadSuccess(image, isCustomImage, processAfterLoad); };
        image.onerror = function () { self.imageLoadFailed(); };
        image.src = source;
    }

    imageLoadSuccess(image: HTMLImageElement, isCustomImage: boolean, processAfterLoad: boolean) {
        if (isCustomImage) {
            this.customImage = image;
        } else {
            this.selectedSampleImage = image;
        }
        if (processAfterLoad) {
            this.processImage();
        }
    }

    imageLoadFailed() {
        this.showError("Original image load failed.");
    }

    showError(error: string) {
        alert(error);
    }

    handleImageProcessed(image: any, errorMessage: any) {
        if (errorMessage !== null) {
            alert(errorMessage);
        }
        else {
            this.outputCanvasHelper.setImage(image);
        }
    }

    processImage() {
        try {
            if (this.currentImage !== null) {
                this.imageProcessor.setImage(this.currentImage);
                this.imageProcessor.processImage(this.handleImageProcessed);

                // Set properties that tell us whether anything has changed:
                this.previousBlockSelections = this.blockSelections;
                this.previousSettings.copyFrom(this.settings);
                this.previousImageSource = this.currentImageSource;
            }
        }
        catch(error) {
            alert(error);
        }
    }
}

