import { Pixel } from '@/logic/Shared/Pixel';

export class Dithererer {
	private doDither: boolean;
	private imageWidth: number;

	private currentRow!: number[];
	private nextRow!: number[];

	public constructor(doDither: boolean, imageWidth: number) {
		this.doDither = doDither;
		this.imageWidth = imageWidth;

		if (this.doDither) {
			this.currentRow = [];
			this.nextRow = [];
			for (let i = 0; i < this.imageWidth * 3; i++) {
				this.currentRow.push(0);
				this.nextRow.push(0);
			}
		}
	}

	public newRow(): void {
		if (this.doDither) {
			this.currentRow = this.nextRow;
			this.nextRow = [];
			for (let i = 0; i < this.imageWidth * 3; i++) {
				this.nextRow.push(0);
			}
		}
	}

	public adjustPixel(pixel: Pixel, x: number): Pixel {
		let red = pixel.red;
		let green = pixel.green;
		let blue = pixel.blue;
		if (this.doDither) {
			red += this.currentRow[x * 3];
			green += this.currentRow[(x * 3) + 1];
			blue += this.currentRow[(x * 3) + 2];
			red = Math.round(red);
			green = Math.round(green);
			blue = Math.round(blue);
			red = red < 0 ? 0 : red > 255 ? 255 : red;
			green = green < 0 ? 0 : green > 255 ? 255 : green;
			blue = blue < 0 ? 0 : blue > 255 ? 255 : blue;
		}
		return new Pixel(red, green, blue, pixel.alpha);
	}

	public accountForDiff(actualColor: Pixel, closestMatch: Pixel, x: number) {
		if (this.doDither) {
			const redDiff = actualColor.red - closestMatch.red;
			const greenDiff = actualColor.green - closestMatch.green;
			const blueDiff = actualColor.blue - closestMatch.blue;

			this.calcDiff(this.currentRow, x + 1, (7 / 16), redDiff, greenDiff, blueDiff);
			this.calcDiff(this.nextRow, x - 1, (3 / 16), redDiff, greenDiff, blueDiff);
			this.calcDiff(this.nextRow, x, (5 / 16), redDiff, greenDiff, blueDiff);
			this.calcDiff(this.nextRow, x + 1, (1 / 16), redDiff, greenDiff, blueDiff);
		}
	}

	private calcDiff(row: number[], x: number, multiplier: number, redDiff: number, greenDiff: number, blueDiff: number) {
		row[x * 3] += multiplier * redDiff;
		row[(x * 3) + 1] += multiplier * greenDiff;
		row[(x * 3) + 2] += multiplier * blueDiff;
	}
}