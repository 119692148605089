
import { Options, Vue } from 'vue-class-component';
import { Block } from '@/logic/Spritecraft/Block';
import { BlocksForCategory } from '@/logic/Spritecraft/BlocksForCategory';
import { Settings } from '@/logic/Spritecraft/Settings';
import { BlockOrientationMode } from '@/logic/Spritecraft/BlockOrientationMode';

@Options({
  props: {
    blocksByCategory: Array,
    settings: Object,
  }
})
export default class BlocksEditor extends Vue {
    modalUp = false;
    blocksByCategory: BlocksForCategory[] = [];
    settings!: Settings;
    collapsedCategories: BlocksForCategory[] = [];

    showModal() {
        // Expand sections with something selected, collapse sections with nothing selected:
        for (const blockCategory of this.blocksByCategory) {
            let collapse = true;
            for (const block of blockCategory.blocks) {
                if (block.isActive) {
                    collapse = false;
                    break;
                }
            }
            if (collapse !== this.isCollapsed(blockCategory)) {
                this.toggleCollapsed(blockCategory);
            }
        }

        this.modalUp = true;
    }

    hideModal() {
        this.modalUp = false;
    }

    get selectedBlocks(): Block[] {
        let returnVal: Block[] = [];
        for (const category of this.blocksByCategory) {
            for (const block of category.blocks) {
                if (block.isActive) {
                    returnVal.push(block);
                }
            }
        }
        return returnVal;
    }

    get allActive(): boolean {
        for (const blockCategory of this.blocksByCategory) {
            if (!blockCategory.allActive) {
                return false;
            }
        }
        return true;
    }

    set allActive(value: boolean) {
        for (const blockCategory of this.blocksByCategory) {
            blockCategory.allActive = value;
        }
    }

    get allCollapsed(): boolean {
        return this.collapsedCategories.length === this.blocksByCategory.length;
    }

    set allCollapsed(value: boolean) {
        for (const blockCategory of this.blocksByCategory) {
            if (value !== this.isCollapsed(blockCategory)) {
                this.toggleCollapsed(blockCategory);
            }
        }
    }

    isCollapsed(blockCategory: BlocksForCategory) {
        return this.collapsedCategories.includes(blockCategory);
    }

    toggleCollapsed(blockCategory: BlocksForCategory) {
        const index = this.collapsedCategories.indexOf(blockCategory);
        if (index > -1) {
            this.collapsedCategories.splice(index, 1);
        } else {
            this.collapsedCategories.push(blockCategory);
        }
    }

    blockImageName(block: Block) {
        if (this.settings.blockOrientationMode === BlockOrientationMode.Top) {
            return block.topImageName;
        } else if (this.settings.blockOrientationMode === BlockOrientationMode.Bottom) {
            return block.bottomImageName;
        } else {
            return block.sideImageName;
        }
    }
}
