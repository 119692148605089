import { Pixel } from '@/logic/Shared/Pixel';
import { ImageUtils } from '../Shared/ImageUtils';

export class BlockFace {
	public origImage: HTMLImageElement;

	private averageColorPrivate: Pixel | null;
	private imageDataCache: Record<number, ImageData>;

	private naturalWidth = 0;

	public constructor(image: HTMLImageElement) {
		this.origImage = image;
		this.averageColorPrivate = null;
		this.imageDataCache = {};
	}

	public getImageData(width = 0): ImageData {

		// This "optimization" assumes that getting this.origImage.width is expensive ... which maybe it's not? I dunno. It probably doesn't hurt.
		if (this.naturalWidth === 0) {
			this.naturalWidth = this.origImage.width;
		}
		
		width = width < 0 ? 0 : width;
		width = width === this.naturalWidth ? 0 : width; // make the natural width always at key 0

		if (!this.imageDataCache[width]) {
			if (!this.origImage.complete) {
				throw new Error("Attempted to get image data before image has loaded.");
			}

			this.imageDataCache[width] = ImageUtils.convertImageToImageData(this.origImage, width, width);
		}
		return this.imageDataCache[width];
	}

	public get averageColor(): Pixel {
		if (!this.averageColorPrivate) {
			this.averageColorPrivate = ImageUtils.getAverageColor(this.getImageData());
		}
		return this.averageColorPrivate;
	}
}