export enum BlockType {
	BlackWool,
	BlueWool,
	BrownWool,
	CyanWool,
	GrayWool,
	GreenWool,
	LightBlueWool,
	LightGrayWool,
	LimeWool,
	MagentaWool,
	OrangeWool,
	PinkWool,
	PurpleWool,
	RedWool,
	WhiteWool,
	YellowWool,
	OakWood,
	StrippedOakWood,
	OakPlanks,
	SpruceWood,
	StrippedSpruceWood,
	SprucePlanks,
	BirchWood,
	StrippedBirchWood,
	BirchPlanks,
	JungleWood,
	StrippedJungleWood,
	JunglePlanks,
	AcaciaWood,
	StrippedAcaciaWood,
	AcaciaPlanks,
	DarkOakWood,
	StrippedDarkOakWood,
	DarkOakPlanks,
	CrimsonHyphaeWood,
	StrippedCrimsonHyphaeWood,
	CrimsonPlanks,
	WarpedHyphaeWood,
	StrippedWarpedHyphaeWood,
	WarpedPlanks,
	Cobblestone,
	MossyCobblestone,
	Stone,
	SmoothStone,
	StoneBricks,
	MossyStoneBricks,
	ChiseledStoneBricks,
	CrackedStoneBricks,
	Andesite,
	PolishedAndesite,
	Diorite,
	PolishedDiorite,
	Granite,
	PolishedGranite,
	IronBlock,
	IronOre,
	GoldBlock,
	GoldOre,
	DiamondBlock,
	DiamondOre,
	CoalBlock,
	CoalOre,
	EmeraldBlock,
	EmeraldOre,
	LapisLazuliBlock,
	LapisLazuliOre,
	RedstoneBlock,
	RedstoneOre,
	Clay,
	Bricks,
	Terracotta,
	WhiteTerracotta,
	OrangeTerracotta,
	MagentaTerracotta,
	LightBlueTerracotta,
	YellowTerracotta,
	LimeTerracotta,
	PinkTerracotta,
	GrayTerracotta,
	LightGrayTerracotta,
	CyanTerracotta,
	PurpleTerracotta,
	BlueTerracotta,
	BrownTerracotta,
	GreenTerracotta,
	RedTerracotta,
	BlackTerracotta,
	WhiteGlazedTerracotta,
	OrangeGlazedTerracotta,
	MagentaGlazedTerracotta,
	LightBlueGlazedTerracotta,
	YellowGlazedTerracotta,
	LimeGlazedTerracotta,
	PinkGlazedTerracotta,
	GrayGlazedTerracotta,
	LightGrayGlazedTerracotta,
	CyanGlazedTerracotta,
	PurpleGlazedTerracotta,
	BlueGlazedTerracotta,
	BrownGlazedTerracotta,
	GreenGlazedTerracotta,
	RedGlazedTerracotta,
	BlackGlazedTerracotta,
	ConcreteBlack,
	ConcreteBlue,
	ConcreteBrown,
	ConcreteCyan,
	ConcreteGray,
	ConcreteGreen,
	ConcreteLightBlue,
	ConcreteLightGray,
	ConcreteLime,
	ConcreteMagenta,
	ConcreteOrange,
	ConcretePink,
	ConcretePurple,
	ConcreteRed,
	ConcreteWhite,
	ConcreteYellow,
	ConcretePowderBlack,
	ConcretePowderBlue,
	ConcretePowderBrown,
	ConcretePowderCyan,
	ConcretePowderGray,
	ConcretePowderGreen ,
	ConcretePowderLightBlue,
	ConcretePowderLightGray,
	ConcretePowderLime,
	ConcretePowderMagenta,
	ConcretePowderOrange,
	ConcretePowderPink,
	ConcretePowderPurple ,
	ConcretePowderRed,
	ConcretePowderWhite,
	ConcretePowderYellow,
	Deepslate,
	CobbledDeepslate,
	DeepslateTiles,
	CrackedDeepslateTiles,
	ChiseledDeepslate,
	PolishedDeepslate,
	DeepslateBricks,
	CrackedDeepslateBricks,
	CopperOre,
	DeepslateCopperOre,
	DeepslateCoalOre,
	DeepslateDiamondOre,
	DeepslateGoldOre,
	DeepslateIronOre,
	DeepslateEmeraldOre,
	DeepslateLapisLazuliOre,
	DeepslateRedstoneOre,
	CopperBlock,
	CutCopperBlock,
	RawCopperBlock,
	AmethystBlock,
	RawGoldBlock,
	RawIronBlock,
	Dripstone,
	Dirt,
	GrassPath,
	CoarseDirt,
	RootedDirt,
	Grass,
	Farmland,
	Gravel,
	Ice,
	PackedIce,
	BlueIce,
	Pumpkin,
	CarvedPumpkin,
	Melon,
	BrownMushroom,
	RedMushroom,
	MushroomStem,
	Mycelium,
	Obsidian,
	CryingObsidian,
	BeeNest,
	Beehive,
	HoneyBlock,
	HoneycombBlock,
	Calcite,
	MossBlock,
	Podzol,
	Snow,
	PowderSnow,
	Shroomlight,
	BoneBlock,
	Bookshelf,
	CraftingTable,
	Loom,
	CartographyTable,
	Furnace,
	BlastFurnace,
	Dispenser,
	Dropper,
	Lodestone,
	FletchingTable,
	HayBale,
	Target,
	Jukebox,
	NoteBlock,
	Observer,
	Piston,
	RespawnAnchor,
	SlimeBlock,
	SmithingTable,
	Smoker,
	TNT,
	Sand,
	RedSand,
	Sandstone,
	SmoothSandstone,
	CutSandstone,
	ChiseledSandstone,
	RedSandstone,
	SmoothRedSandstone,
	CutRedSandstone,
	ChiseledRedSandstone,
	Cactus,
	Netherrack,
	SoulSand,
	SoulSoil,
	Glowstone,
	RedstoneLamp,
	MagmaBlock,
	NetherWartBlock,
	CrimsonNylium,
	WarpedNylium,
	AncientDebris,
	NetheriteBlock,
	NetherGoldOre,
	NetherQuartzOre,
	QuartzBlock,
	SmoothQuartzBlock,
	QuartzBricks,
	QuartzPillar,
	ChiseledQuartzBlock,
	Basalt,
	SmoothBasalt,
	PolishedBasalt,
	NetherBricks,
	RedNetherBricks,
	CrackedNetherBricks,
	ChiseledNetherBricks,
	Blackstone,
	GildedBlackstone,
	PolishedBlackstone,
	ChiseledPolishedBlackstone,
	PolishedBlackstoneBricks,
	CrackedPolishedBlackstoneBricks,
	EndStone,
	EndStoneBricks,
	PurpurBlock,
	PurpurPillar,
	ShulkerBox,
	ShulkerBoxBlack,
	ShulkerBoxBlue,
	ShulkerBoxBrown,
	ShulkerBoxCyan,
	ShulkerBoxGray,
	ShulkerBoxGreen,
	ShulkerBoxLightBlue,
	ShulkerBoxLightGray,
	ShulkerBoxLime,
	ShulkerBoxMagenta,
	ShulkerBoxOrange,
	ShulkerBoxPink,
	ShulkerBoxPurple,
	ShulkerBoxRed,
	ShulkerBoxWhite,
	ShulkerBoxYellow,
	Prismarine,
	PrismarineBricks,
	DarkPrismarine,
	DriedKelpBlock,
	SeaLantern,
	BrainCoralBlock,
	BubbleCoralBlock,
	FireCoralBlock,
	HornCoralBlock,
	TubeCoralBlock,
	DeadBrainCoralBlock,
	DeadBubbleCoralBlock,
	DeadFireCoralBlock,
	DeadHornCoralBlock,
	DeadTubeCoralBlock,
	Bedrock,
	Sponge,
	WetSponge,
	BuddingAmethyst,

	SpeedCubeWhite,
	SpeedCubeYellow,
	SpeedCubeBlue,
	SpeedCubeGreen,
	SpeedCubeRed,
	SpeedCubeOrange,
}