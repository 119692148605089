
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    blocksByCategory: Array,
    settings: Object,
  }
})
export default class DonateArea extends Vue {
    closed = false;

    closeIt() {
        this.closed = true;
    }
}
