export class BlockImages {
    // Wool:
    public BlackWool = require("@/assets/images/spritecraft/blocks/black_wool.png");
    public BlueWool = require("@/assets/images/spritecraft/blocks/blue_wool.png");
    public BrownWool = require("@/assets/images/spritecraft/blocks/brown_wool.png");
    public CyanWool = require("@/assets/images/spritecraft/blocks/cyan_wool.png");
    public GrayWool = require("@/assets/images/spritecraft/blocks/gray_wool.png");
    public GreenWool = require("@/assets/images/spritecraft/blocks/green_wool.png");
    public LightBlueWool = require("@/assets/images/spritecraft/blocks/light_blue_wool.png");
    public LightGrayWool = require("@/assets/images/spritecraft/blocks/light_gray_wool.png");
    public LimeWool = require("@/assets/images/spritecraft/blocks/lime_wool.png");
    public MagentaWool = require("@/assets/images/spritecraft/blocks/magenta_wool.png");
    public OrangeWool = require("@/assets/images/spritecraft/blocks/orange_wool.png");
    public PinkWool = require("@/assets/images/spritecraft/blocks/pink_wool.png");
    public PurpleWool = require("@/assets/images/spritecraft/blocks/purple_wool.png");
    public RedWool = require("@/assets/images/spritecraft/blocks/red_wool.png");
    public WhiteWool = require("@/assets/images/spritecraft/blocks/white_wool.png");
    public YellowWool = require("@/assets/images/spritecraft/blocks/yellow_wool.png");
    // Wood:
    public OakWood = require("@/assets/images/spritecraft/blocks/oak_log.png"); 
    public OakWoodTop = require("@/assets/images/spritecraft/blocks/oak_log_top.png");
    public StrippedOakWood = require("@/assets/images/spritecraft/blocks/stripped_oak_log.png"); 
    public StrippedOakWoodTop = require("@/assets/images/spritecraft/blocks/stripped_oak_log_top.png");
    public OakPlanks = require("@/assets/images/spritecraft/blocks/oak_planks.png");
    public SpruceWood = require("@/assets/images/spritecraft/blocks/spruce_log.png"); 
    public SpruceWoodTop = require("@/assets/images/spritecraft/blocks/spruce_log_top.png");
    public StrippedSpruceWood = require("@/assets/images/spritecraft/blocks/stripped_spruce_log.png"); 
    public StrippedSpruceWoodTop = require("@/assets/images/spritecraft/blocks/stripped_spruce_log_top.png");
    public SprucePlanks = require("@/assets/images/spritecraft/blocks/spruce_planks.png");
    public BirchWood = require("@/assets/images/spritecraft/blocks/birch_log.png"); 
    public BirchWoodTop = require("@/assets/images/spritecraft/blocks/birch_log_top.png");
    public StrippedBirchWood = require("@/assets/images/spritecraft/blocks/stripped_birch_log.png"); 
    public StrippedBirchWoodTop = require("@/assets/images/spritecraft/blocks/stripped_birch_log_top.png");
    public BirchPlanks = require("@/assets/images/spritecraft/blocks/birch_planks.png");
    public JungleWood = require("@/assets/images/spritecraft/blocks/jungle_log.png"); 
    public JungleWoodTop = require("@/assets/images/spritecraft/blocks/jungle_log_top.png");
    public StrippedJungleWood = require("@/assets/images/spritecraft/blocks/stripped_jungle_log.png"); 
    public StrippedJungleWoodTop = require("@/assets/images/spritecraft/blocks/stripped_jungle_log_top.png");
    public JunglePlanks = require("@/assets/images/spritecraft/blocks/jungle_planks.png");
    public AcaciaWood = require("@/assets/images/spritecraft/blocks/acacia_log.png"); 
    public AcaciaWoodTop = require("@/assets/images/spritecraft/blocks/acacia_log_top.png");
    public StrippedAcaciaWood = require("@/assets/images/spritecraft/blocks/stripped_acacia_log.png"); 
    public StrippedAcaciaWoodTop = require("@/assets/images/spritecraft/blocks/stripped_acacia_log_top.png");
    public AcaciaPlanks = require("@/assets/images/spritecraft/blocks/acacia_planks.png");
    public DarkOakWood = require("@/assets/images/spritecraft/blocks/dark_oak_log.png"); 
    public DarkOakWoodTop = require("@/assets/images/spritecraft/blocks/dark_oak_log_top.png");
    public StrippedDarkOakWood = require("@/assets/images/spritecraft/blocks/stripped_dark_oak_log.png"); 
    public StrippedDarkOakWoodTop = require("@/assets/images/spritecraft/blocks/stripped_dark_oak_log_top.png");
    public DarkOakPlanks = require("@/assets/images/spritecraft/blocks/dark_oak_planks.png");
    public CrimsonHyphaeWood = require("@/assets/images/spritecraft/blocks/AA_modified_crimson_stem.png"); 
    public CrimsonHyphaeWoodTop = require("@/assets/images/spritecraft/blocks/crimson_stem_top.png");
    public StrippedCrimsonHyphaeWood = require("@/assets/images/spritecraft/blocks/stripped_crimson_stem.png"); 
    public StrippedCrimsonHyphaeWoodTop = require("@/assets/images/spritecraft/blocks/stripped_crimson_stem_top.png");
    public CrimsonPlanks = require("@/assets/images/spritecraft/blocks/crimson_planks.png");
    public WarpedHyphaeWood = require("@/assets/images/spritecraft/blocks/AA_modified_warped_stem.png"); 
    public WarpedHyphaeWoodTop = require("@/assets/images/spritecraft/blocks/warped_stem_top.png");
    public StrippedWarpedHyphaeWood = require("@/assets/images/spritecraft/blocks/stripped_warped_stem.png"); 
    public StrippedWarpedHyphaeWoodTop = require("@/assets/images/spritecraft/blocks/stripped_warped_stem_top.png");
    public WarpedPlanks = require("@/assets/images/spritecraft/blocks/warped_planks.png");
    // Stone:
    public Stone = require("@/assets/images/spritecraft/blocks/stone.png");
    public Cobblestone = require("@/assets/images/spritecraft/blocks/cobblestone.png");
    public MossyCobblestone = require("@/assets/images/spritecraft/blocks/mossy_cobblestone.png");
    public SmoothStone = require("@/assets/images/spritecraft/blocks/smooth_stone.png");
    public StoneBricks = require("@/assets/images/spritecraft/blocks/stone_bricks.png");
    public MossyStoneBricks = require("@/assets/images/spritecraft/blocks/mossy_stone_bricks.png");
    public ChiseledStoneBricks = require("@/assets/images/spritecraft/blocks/chiseled_stone_bricks.png");
    public CrackedStoneBricks = require("@/assets/images/spritecraft/blocks/cracked_stone_bricks.png");
    public Andesite = require("@/assets/images/spritecraft/blocks/andesite.png");
    public PolishedAndesite = require("@/assets/images/spritecraft/blocks/polished_andesite.png");
    public Diorite = require("@/assets/images/spritecraft/blocks/diorite.png");
    public PolishedDiorite = require("@/assets/images/spritecraft/blocks/polished_diorite.png");
    public Granite = require("@/assets/images/spritecraft/blocks/granite.png");
    public PolishedGranite = require("@/assets/images/spritecraft/blocks/polished_granite.png");
    public Deepslate = require("@/assets/images/spritecraft/blocks/deepslate.png");
    public CobbledDeepslate = require("@/assets/images/spritecraft/blocks/cobbled_deepslate.png");
    public DeepslateTiles = require("@/assets/images/spritecraft/blocks/deepslate_tiles.png");
    public CrackedDeepslateTiles = require("@/assets/images/spritecraft/blocks/cracked_deepslate_tiles.png");
    public ChiseledDeepslate = require("@/assets/images/spritecraft/blocks/chiseled_deepslate.png");
    public PolishedDeepslate = require("@/assets/images/spritecraft/blocks/polished_deepslate.png");
    public DeepslateBricks = require("@/assets/images/spritecraft/blocks/deepslate_bricks.png");
    public CrackedDeepslateBricks = require("@/assets/images/spritecraft/blocks/cracked_deepslate_bricks.png");
    public Dripstone = require("@/assets/images/spritecraft/blocks/dripstone_block.png");
    // Ore:
    public IronBlock = require("@/assets/images/spritecraft/blocks/iron_block.png");
    public IronOre = require("@/assets/images/spritecraft/blocks/iron_ore.png");
    public DeepslateIronOre = require("@/assets/images/spritecraft/blocks/deepslate_iron_ore.png");
    public RawIron = require("@/assets/images/spritecraft/blocks/raw_iron_block.png");
    public GoldBlock = require("@/assets/images/spritecraft/blocks/gold_block.png");
    public GoldOre = require("@/assets/images/spritecraft/blocks/gold_ore.png");
    public DeepslateGoldOre = require("@/assets/images/spritecraft/blocks/deepslate_gold_ore.png");
    public RawGold = require("@/assets/images/spritecraft/blocks/raw_gold_block.png");
    public DiamondBlock = require("@/assets/images/spritecraft/blocks/diamond_block.png");
    public DiamondOre = require("@/assets/images/spritecraft/blocks/diamond_ore.png");
    public DeepslateDiamondOre = require("@/assets/images/spritecraft/blocks/deepslate_diamond_ore.png");
    public CoalBlock = require("@/assets/images/spritecraft/blocks/coal_block.png");
    public CoalOre = require("@/assets/images/spritecraft/blocks/coal_ore.png");
    public DeepslateCoalOre = require("@/assets/images/spritecraft/blocks/deepslate_coal_ore.png");
    public EmeraldBlock = require("@/assets/images/spritecraft/blocks/emerald_block.png");
    public EmeraldOre = require("@/assets/images/spritecraft/blocks/emerald_ore.png");
    public DeepslateEmeraldOre = require("@/assets/images/spritecraft/blocks/deepslate_emerald_ore.png");
    public LapisBlock = require("@/assets/images/spritecraft/blocks/lapis_block.png");
    public LapisOre = require("@/assets/images/spritecraft/blocks/lapis_ore.png");
    public DeepslateLapisOre = require("@/assets/images/spritecraft/blocks/deepslate_lapis_ore.png");
    public RedstoneBlock = require("@/assets/images/spritecraft/blocks/redstone_block.png");
    public RedstoneOre = require("@/assets/images/spritecraft/blocks/redstone_ore.png");
    public DeepslateRedstoneOre = require("@/assets/images/spritecraft/blocks/deepslate_redstone_ore.png");
    public CopperBlock = require("@/assets/images/spritecraft/blocks/copper_block.png");
    public CopperOre = require("@/assets/images/spritecraft/blocks/copper_ore.png");
    public DeepslateCopperOre = require("@/assets/images/spritecraft/blocks/deepslate_copper_ore.png");
    public RawCopper = require("@/assets/images/spritecraft/blocks/raw_copper_block.png");
    public CutCopperBlock = require("@/assets/images/spritecraft/blocks/cut_copper.png");
    public AmethystBlock = require("@/assets/images/spritecraft/blocks/amethyst_block.png");
    // Clay:
    public Clay = require("@/assets/images/spritecraft/blocks/clay.png");
    public Bricks = require("@/assets/images/spritecraft/blocks/bricks.png");
    public Terracotta = require("@/assets/images/spritecraft/blocks/terracotta.png");
    public TerracottaWhite = require("@/assets/images/spritecraft/blocks/white_terracotta.png");
    public TerracottaOrange = require("@/assets/images/spritecraft/blocks/orange_terracotta.png");
    public TerracottaMagenta = require("@/assets/images/spritecraft/blocks/magenta_terracotta.png");
    public TerracottaLightBlue = require("@/assets/images/spritecraft/blocks/light_blue_terracotta.png");
    public TerracottaYellow = require("@/assets/images/spritecraft/blocks/yellow_terracotta.png");
    public TerracottaLime = require("@/assets/images/spritecraft/blocks/lime_terracotta.png");
    public TerracottaPink = require("@/assets/images/spritecraft/blocks/pink_terracotta.png");
    public TerracottaGray = require("@/assets/images/spritecraft/blocks/gray_terracotta.png");
    public TerracottaLightGray = require("@/assets/images/spritecraft/blocks/light_gray_terracotta.png");
    public TerracottaCyan = require("@/assets/images/spritecraft/blocks/cyan_terracotta.png");
    public TerracottaPurple = require("@/assets/images/spritecraft/blocks/purple_terracotta.png");
    public TerracottaBlue = require("@/assets/images/spritecraft/blocks/blue_terracotta.png");
    public TerracottaBrown = require("@/assets/images/spritecraft/blocks/brown_terracotta.png");
    public TerracottaGreen = require("@/assets/images/spritecraft/blocks/green_terracotta.png");
    public TerracottaRed = require("@/assets/images/spritecraft/blocks/red_terracotta.png");
    public TerracottaBlack = require("@/assets/images/spritecraft/blocks/black_terracotta.png");
    public TerracottaWhiteGlazed = require("@/assets/images/spritecraft/blocks/white_glazed_terracotta.png");
    public TerracottaOrangeGlazed = require("@/assets/images/spritecraft/blocks/orange_glazed_terracotta.png");
    public TerracottaMagentaGlazed = require("@/assets/images/spritecraft/blocks/magenta_glazed_terracotta.png");
    public TerracottaLightBlueGlazed = require("@/assets/images/spritecraft/blocks/light_blue_glazed_terracotta.png");
    public TerracottaYellowGlazed = require("@/assets/images/spritecraft/blocks/yellow_glazed_terracotta.png");
    public TerracottaLimeGlazed = require("@/assets/images/spritecraft/blocks/lime_glazed_terracotta.png");
    public TerracottaPinkGlazed = require("@/assets/images/spritecraft/blocks/pink_glazed_terracotta.png");
    public TerracottaGrayGlazed = require("@/assets/images/spritecraft/blocks/gray_glazed_terracotta.png");
    public TerracottaLightGrayGlazed = require("@/assets/images/spritecraft/blocks/light_gray_glazed_terracotta.png");
    public TerracottaCyanGlazed = require("@/assets/images/spritecraft/blocks/cyan_glazed_terracotta.png");
    public TerracottaPurpleGlazed = require("@/assets/images/spritecraft/blocks/purple_glazed_terracotta.png");
    public TerracottaBlueGlazed = require("@/assets/images/spritecraft/blocks/blue_glazed_terracotta.png");
    public TerracottaBrownGlazed = require("@/assets/images/spritecraft/blocks/brown_glazed_terracotta.png");
    public TerracottaGreenGlazed = require("@/assets/images/spritecraft/blocks/green_glazed_terracotta.png");
    public TerracottaRedGlazed = require("@/assets/images/spritecraft/blocks/red_glazed_terracotta.png");
    public TerracottaBlackGlazed = require("@/assets/images/spritecraft/blocks/black_glazed_terracotta.png");
    // Concrete:
    public ConcreteBlack = require("@/assets/images/spritecraft/blocks/black_concrete.png");
    public ConcreteBlue = require("@/assets/images/spritecraft/blocks/blue_concrete.png");
    public ConcreteBrown = require("@/assets/images/spritecraft/blocks/brown_concrete.png");
    public ConcreteCyan = require("@/assets/images/spritecraft/blocks/cyan_concrete.png");
    public ConcreteGray = require("@/assets/images/spritecraft/blocks/gray_concrete.png");
    public ConcreteGreen = require("@/assets/images/spritecraft/blocks/green_concrete.png");
    public ConcreteLightBlue = require("@/assets/images/spritecraft/blocks/light_blue_concrete.png");
    public ConcreteLightGray = require("@/assets/images/spritecraft/blocks/light_gray_concrete.png");
    public ConcreteLime = require("@/assets/images/spritecraft/blocks/lime_concrete.png");
    public ConcreteMagenta = require("@/assets/images/spritecraft/blocks/magenta_concrete.png");
    public ConcreteOrange = require("@/assets/images/spritecraft/blocks/orange_concrete.png");
    public ConcretePink = require("@/assets/images/spritecraft/blocks/pink_concrete.png");
    public ConcretePurple = require("@/assets/images/spritecraft/blocks/purple_concrete.png");
    public ConcreteRed = require("@/assets/images/spritecraft/blocks/red_concrete.png");
    public ConcreteWhite = require("@/assets/images/spritecraft/blocks/white_concrete.png");
    public ConcreteYellow = require("@/assets/images/spritecraft/blocks/yellow_concrete.png");
    public ConcretePowderBlack = require("@/assets/images/spritecraft/blocks/black_concrete_powder.png");
    public ConcretePowderBlue = require("@/assets/images/spritecraft/blocks/blue_concrete_powder.png");
    public ConcretePowderBrown = require("@/assets/images/spritecraft/blocks/brown_concrete_powder.png");
    public ConcretePowderCyan = require("@/assets/images/spritecraft/blocks/cyan_concrete_powder.png");
    public ConcretePowderGray = require("@/assets/images/spritecraft/blocks/gray_concrete_powder.png");
    public ConcretePowderGreen = require("@/assets/images/spritecraft/blocks/green_concrete_powder.png");
    public ConcretePowderLightBlue = require("@/assets/images/spritecraft/blocks/light_blue_concrete_powder.png");
    public ConcretePowderLightGray = require("@/assets/images/spritecraft/blocks/light_gray_concrete_powder.png");
    public ConcretePowderLime = require("@/assets/images/spritecraft/blocks/lime_concrete_powder.png");
    public ConcretePowderMagenta = require("@/assets/images/spritecraft/blocks/magenta_concrete_powder.png");
    public ConcretePowderOrange = require("@/assets/images/spritecraft/blocks/orange_concrete_powder.png");
    public ConcretePowderPink = require("@/assets/images/spritecraft/blocks/pink_concrete_powder.png");
    public ConcretePowderPurple = require("@/assets/images/spritecraft/blocks/purple_concrete_powder.png");
    public ConcretePowderRed = require("@/assets/images/spritecraft/blocks/red_concrete_powder.png");
    public ConcretePowderWhite = require("@/assets/images/spritecraft/blocks/white_concrete_powder.png");
    public ConcretePowderYellow = require("@/assets/images/spritecraft/blocks/yellow_concrete_powder.png");
    // Natural:
    public Dirt = require("@/assets/images/spritecraft/blocks/dirt.png");
    public CoarseDirt = require("@/assets/images/spritecraft/blocks/coarse_dirt.png");
    public Grass = require("@/assets/images/spritecraft/blocks/grass_block_side.png"); 
    public GrassTop = require("@/assets/images/spritecraft/blocks/grass_block_top.png");
    public GrassPath = require("@/assets/images/spritecraft/blocks/grass_path_side.png"); 
    public GrassPathTop = require("@/assets/images/spritecraft/blocks/grass_path_top.png");
    public Farmland = require("@/assets/images/spritecraft/blocks/farmland.png");
    public Gravel = require("@/assets/images/spritecraft/blocks/gravel.png");
    public Ice = require("@/assets/images/spritecraft/blocks/ice.png");
    public PackedIce = require("@/assets/images/spritecraft/blocks/packed_ice.png");
    public BlueIce = require("@/assets/images/spritecraft/blocks/blue_ice.png");
    public Pumpkin = require("@/assets/images/spritecraft/blocks/pumpkin_side.png"); 
    public CarvedPumpkin = require("@/assets/images/spritecraft/blocks/carved_pumpkin.png"); 
    public PumpkinTop = require("@/assets/images/spritecraft/blocks/pumpkin_top.png");
    public Melon = require("@/assets/images/spritecraft/blocks/melon_side.png"); 
    public MelonTop = require("@/assets/images/spritecraft/blocks/melon_top.png");
    public BrownMushroom = require("@/assets/images/spritecraft/blocks/brown_mushroom_block.png");
    public RedMushroom = require("@/assets/images/spritecraft/blocks/red_mushroom_block.png");
    public MushroomStem = require("@/assets/images/spritecraft/blocks/mushroom_stem.png");
    public Mycelium = require("@/assets/images/spritecraft/blocks/mycelium_side.png"); 
    public MyceliumTop = require("@/assets/images/spritecraft/blocks/mycelium_top.png");
    public Obsidian = require("@/assets/images/spritecraft/blocks/obsidian.png");
    public CryingObsidian = require("@/assets/images/spritecraft/blocks/crying_obsidian.png");
    public BeeNest = require("@/assets/images/spritecraft/blocks/bee_nest_side.png"); 
    public BeeNestFront = require("@/assets/images/spritecraft/blocks/bee_nest_front.png");
    public BeeNestFrontHoney = require("@/assets/images/spritecraft/blocks/bee_nest_front_honey.png");
    public BeeNestTop = require("@/assets/images/spritecraft/blocks/bee_nest_top.png");
    public BeeNestBottom = require("@/assets/images/spritecraft/blocks/bee_nest_bottom.png");
    public Beehive = require("@/assets/images/spritecraft/blocks/beehive_side.png");
    public BeehiveFront = require("@/assets/images/spritecraft/blocks/beehive_front.png");
    public BeehiveFrontHoney = require("@/assets/images/spritecraft/blocks/beehive_front_honey.png");
    public BeehiveTop = require("@/assets/images/spritecraft/blocks/beehive_end.png");
    public HoneyBlock = require("@/assets/images/spritecraft/blocks/honey_block_side.png"); 
    public HoneyBlockTop = require("@/assets/images/spritecraft/blocks/honey_block_top.png");
    public HoneycombBlock = require("@/assets/images/spritecraft/blocks/honeycomb_block.png");
    public Calcite = require("@/assets/images/spritecraft/blocks/calcite.png");
    public MossBlock = require("@/assets/images/spritecraft/blocks/moss_block.png");
    public Podzol = require("@/assets/images/spritecraft/blocks/podzol_side.png"); 
    public Podzol_Top = require("@/assets/images/spritecraft/blocks/podzol_top.png");
    public Snow = require("@/assets/images/spritecraft/blocks/snow.png");
    public PowderedSnow = require("@/assets/images/spritecraft/blocks/powder_snow.png");
    public Shroomlight = require("@/assets/images/spritecraft/blocks/shroomlight.png");
    public BoneBlock = require("@/assets/images/spritecraft/blocks/bone_block_side.png");
    public BoneBlockTop = require("@/assets/images/spritecraft/blocks/bone_block_top.png");
    // Crafted:
    public Bookshelf = require("@/assets/images/spritecraft/blocks/bookshelf.png");
    public CraftingTableFront = require("@/assets/images/spritecraft/blocks/crafting_table_front.png");
    public CraftingTableSide = require("@/assets/images/spritecraft/blocks/crafting_table_side.png");
    public CraftingTableTop = require("@/assets/images/spritecraft/blocks/crafting_table_top.png");
    public Loom = require("@/assets/images/spritecraft/blocks/loom_front.png");
    public LoomSide = require("@/assets/images/spritecraft/blocks/loom_side.png");
    public LoomTop = require("@/assets/images/spritecraft/blocks/loom_top.png");
    public LoomBottom = require("@/assets/images/spritecraft/blocks/loom_bottom.png");
    public CartographyTable = require("@/assets/images/spritecraft/blocks/cartography_table_side1.png");
    public CartographyTableSide2 = require("@/assets/images/spritecraft/blocks/cartography_table_side2.png");
    public CartographyTableSide3 = require("@/assets/images/spritecraft/blocks/cartography_table_side3.png");
    public CartographyTableTop = require("@/assets/images/spritecraft/blocks/cartography_table_top.png");
    public Furnace = require("@/assets/images/spritecraft/blocks/furnace_front.png");
    public FurnaceSide = require("@/assets/images/spritecraft/blocks/furnace_side.png");
    public FurnaceTop = require("@/assets/images/spritecraft/blocks/furnace_top.png");
    public BlastFurnace = require("@/assets/images/spritecraft/blocks/blast_furnace_front.png");
    public BlastFurnaceSide = require("@/assets/images/spritecraft/blocks/blast_furnace_side.png");
    public BlastFurnaceTop = require("@/assets/images/spritecraft/blocks/blast_furnace_top.png");
    public Dispenser = require("@/assets/images/spritecraft/blocks/dispenser_front.png");
    public Dropper = require("@/assets/images/spritecraft/blocks/dropper_front.png");
    public Lodestone = require("@/assets/images/spritecraft/blocks/lodestone_side.png");
    public LodestoneTop = require("@/assets/images/spritecraft/blocks/lodestone_top.png");
    public FletchingTable = require("@/assets/images/spritecraft/blocks/fletching_table_front.png");
    public FletchingTableSide = require("@/assets/images/spritecraft/blocks/fletching_table_side.png");
    public FletchingTableTop = require("@/assets/images/spritecraft/blocks/fletching_table_top.png");
    public HayBale = require("@/assets/images/spritecraft/blocks/hay_block_side.png");
    public HayBaleTop = require("@/assets/images/spritecraft/blocks/hay_block_top.png");
    public Target = require("@/assets/images/spritecraft/blocks/target_side.png");
    public TargetTop = require("@/assets/images/spritecraft/blocks/target_top.png");
    public Jukebox = require("@/assets/images/spritecraft/blocks/jukebox_side.png");
    public JukeboxTop = require("@/assets/images/spritecraft/blocks/jukebox_top.png");
    public NoteBlock = require("@/assets/images/spritecraft/blocks/note_block.png");
    public Observer = require("@/assets/images/spritecraft/blocks/observer_front.png");
    public ObserverSide = require("@/assets/images/spritecraft/blocks/observer_side.png");
    public ObserverBack = require("@/assets/images/spritecraft/blocks/observer_back.png");
    public ObserverTop = require("@/assets/images/spritecraft/blocks/observer_top.png");
    public Piston = require("@/assets/images/spritecraft/blocks/piston_side.png");
    public PistonTop = require("@/assets/images/spritecraft/blocks/piston_top.png");
    public PistonBottom = require("@/assets/images/spritecraft/blocks/piston_bottom.png");
    public RespawnAnchor = require("@/assets/images/spritecraft/blocks/respawn_anchor_side0.png");
    public RespawnAnchorSide1 = require("@/assets/images/spritecraft/blocks/respawn_anchor_side1.png");
    public RespawnAnchorSide2 = require("@/assets/images/spritecraft/blocks/respawn_anchor_side2.png");
    public RespawnAnchorSide3 = require("@/assets/images/spritecraft/blocks/respawn_anchor_side3.png");
    public RespawnAnchorSide4 = require("@/assets/images/spritecraft/blocks/respawn_anchor_side4.png");
    public RespawnAnchorTop = require("@/assets/images/spritecraft/blocks/respawn_anchor_top_off.png");
    public RespawnAnchorBottom = require("@/assets/images/spritecraft/blocks/respawn_anchor_bottom.png");
    public SlimeBlock = require("@/assets/images/spritecraft/blocks/slime_block.png");
    public SmithingTable = require("@/assets/images/spritecraft/blocks/smithing_table_front.png");
    public SmithingTableSide = require("@/assets/images/spritecraft/blocks/smithing_table_side.png");
    public SmithingTableTop = require("@/assets/images/spritecraft/blocks/smithing_table_top.png");
    public SmithingTableBottom = require("@/assets/images/spritecraft/blocks/smithing_table_bottom.png");
    public Smoker = require("@/assets/images/spritecraft/blocks/smoker_front.png");
    public SmokerSide = require("@/assets/images/spritecraft/blocks/smoker_side.png");
    public SmokerTop = require("@/assets/images/spritecraft/blocks/smoker_top.png");
    public SmokerBottom = require("@/assets/images/spritecraft/blocks/smoker_bottom.png");
    public TNT = require("@/assets/images/spritecraft/blocks/tnt_side.png");
    public TNTTop = require("@/assets/images/spritecraft/blocks/tnt_top.png");
    public TNTBottom = require("@/assets/images/spritecraft/blocks/tnt_bottom.png");
    // Desert:
    public Sand = require("@/assets/images/spritecraft/blocks/sand.png");
    public RedSand = require("@/assets/images/spritecraft/blocks/red_sand.png");
    public Sandstone = require("@/assets/images/spritecraft/blocks/sandstone.png");
    public SandstoneTop = require("@/assets/images/spritecraft/blocks/sandstone_top.png");
    public SandstoneBottom = require("@/assets/images/spritecraft/blocks/sandstone_bottom.png");
    public CutSandstone = require("@/assets/images/spritecraft/blocks/cut_sandstone.png");
    public ChiseledSandstone = require("@/assets/images/spritecraft/blocks/chiseled_sandstone.png");
    public RedSandstone = require("@/assets/images/spritecraft/blocks/red_sandstone.png");
    public RedSandstoneTop = require("@/assets/images/spritecraft/blocks/red_sandstone_top.png");
    public RedSandstoneBottom = require("@/assets/images/spritecraft/blocks/red_sandstone_bottom.png");
    public CutRedSandstone = require("@/assets/images/spritecraft/blocks/cut_red_sandstone.png");
    public ChiseledRedSandstone = require("@/assets/images/spritecraft/blocks/chiseled_red_sandstone.png");
    public Cactus = require("@/assets/images/spritecraft/blocks/cactus_side.png");
    public CactusTop = require("@/assets/images/spritecraft/blocks/cactus_top.png");
    public CactusBottom = require("@/assets/images/spritecraft/blocks/cactus_bottom.png");
    // Nether:
    public Netherrack = require("@/assets/images/spritecraft/blocks/netherrack.png");
    public SoulSand = require("@/assets/images/spritecraft/blocks/soul_sand.png");
    public SoulSoil = require("@/assets/images/spritecraft/blocks/soul_soil.png");
    public Glowstone = require("@/assets/images/spritecraft/blocks/glowstone.png");
    public RedstoneLamp = require("@/assets/images/spritecraft/blocks/redstone_lamp.png");
    public MagmaBlock = require("@/assets/images/spritecraft/blocks/AA_modified_magma.png");
    public NetherWartBlock = require("@/assets/images/spritecraft/blocks/nether_wart_block.png");
    public CrimsonNylium = require("@/assets/images/spritecraft/blocks/crimson_nylium_side.png");
    public CrimsonNyliumTop = require("@/assets/images/spritecraft/blocks/crimson_nylium.png");
    public WarpedNylium = require("@/assets/images/spritecraft/blocks/warped_nylium_side.png");
    public WarpedNyliumTop = require("@/assets/images/spritecraft/blocks/warped_nylium.png");
    public AncientDebris = require("@/assets/images/spritecraft/blocks/ancient_debris_side.png");
    public AncientDebrisTop = require("@/assets/images/spritecraft/blocks/ancient_debris_top.png");
    public NetheriteBlock = require("@/assets/images/spritecraft/blocks/netherite_block.png");
    public NetherGoldOre = require("@/assets/images/spritecraft/blocks/nether_gold_ore.png");
    public NetherQuartzOre = require("@/assets/images/spritecraft/blocks/nether_quartz_ore.png");
    public QuartzBlock = require("@/assets/images/spritecraft/blocks/quartz_block_side.png");
    public QuartzBlockTop = require("@/assets/images/spritecraft/blocks/quartz_block_top.png");
    public QuartzBlockBottom = require("@/assets/images/spritecraft/blocks/quartz_block_bottom.png");
    public QuartzBricks = require("@/assets/images/spritecraft/blocks/quartz_bricks.png");
    public QuartzPillar = require("@/assets/images/spritecraft/blocks/quartz_pillar.png");
    public QuartzPillarTop = require("@/assets/images/spritecraft/blocks/quartz_pillar_top.png");
    public ChiseledQuartzBlock = require("@/assets/images/spritecraft/blocks/chiseled_quartz_block.png");
    public ChiseledQuartzBlockTop = require("@/assets/images/spritecraft/blocks/chiseled_quartz_block_top.png");
    public Basalt = require("@/assets/images/spritecraft/blocks/basalt_side.png");
    public BasaltTop = require("@/assets/images/spritecraft/blocks/basalt_top.png");
    public SmoothBasalt = require("@/assets/images/spritecraft/blocks/smooth_basalt.png");
    public PolishedBasalt = require("@/assets/images/spritecraft/blocks/polished_basalt_side.png");
    public PolishedBasaltTop = require("@/assets/images/spritecraft/blocks/polished_basalt_top.png");
    public NetherBricks = require("@/assets/images/spritecraft/blocks/nether_bricks.png");
    public RedNetherBricks = require("@/assets/images/spritecraft/blocks/red_nether_bricks.png");
    public CrackedNetherBricks = require("@/assets/images/spritecraft/blocks/cracked_nether_bricks.png");
    public ChiseledNetherBricks = require("@/assets/images/spritecraft/blocks/chiseled_nether_bricks.png");
    public Blackstone = require("@/assets/images/spritecraft/blocks/blackstone.png");
    public BlackstoneTop = require("@/assets/images/spritecraft/blocks/blackstone_top.png");
    public GildedBlackstone = require("@/assets/images/spritecraft/blocks/gilded_blackstone.png");
    public PolishedBlackstone = require("@/assets/images/spritecraft/blocks/polished_blackstone.png");
    public ChiseledPolishedBlackstone = require("@/assets/images/spritecraft/blocks/chiseled_polished_blackstone.png");
    public PolishedBlackstoneBricks = require("@/assets/images/spritecraft/blocks/polished_blackstone_bricks.png");
    public CrackedPolishedBlackstoneBricks = require("@/assets/images/spritecraft/blocks/cracked_polished_blackstone_bricks.png");
    // The End:
    public EndStone = require("@/assets/images/spritecraft/blocks/end_stone.png");
    public EndStoneBricks = require("@/assets/images/spritecraft/blocks/end_stone_bricks.png");
    public PurpurBlock = require("@/assets/images/spritecraft/blocks/purpur_block.png");
    public PurpurPillar = require("@/assets/images/spritecraft/blocks/purpur_pillar.png");
    public PurpurPillarTop = require("@/assets/images/spritecraft/blocks/purpur_pillar_top.png");
    public ShulkerBox = require("@/assets/images/spritecraft/blocks/shulker_box.png");
    public ShulkerBoxBlack = require("@/assets/images/spritecraft/blocks/black_shulker_box.png");
    public ShulkerBoxBlue = require("@/assets/images/spritecraft/blocks/blue_shulker_box.png");
    public ShulkerBoxBrown = require("@/assets/images/spritecraft/blocks/brown_shulker_box.png");
    public ShulkerBoxCyan = require("@/assets/images/spritecraft/blocks/cyan_shulker_box.png");
    public ShulkerBoxGray = require("@/assets/images/spritecraft/blocks/gray_shulker_box.png");
    public ShulkerBoxGreen = require("@/assets/images/spritecraft/blocks/green_shulker_box.png");
    public ShulkerBoxLightBlue = require("@/assets/images/spritecraft/blocks/light_blue_shulker_box.png");
    public ShulkerBoxLightGray = require("@/assets/images/spritecraft/blocks/light_gray_shulker_box.png");
    public ShulkerBoxLime = require("@/assets/images/spritecraft/blocks/lime_shulker_box.png");
    public ShulkerBoxMagenta = require("@/assets/images/spritecraft/blocks/magenta_shulker_box.png");
    public ShulkerBoxOrange = require("@/assets/images/spritecraft/blocks/orange_shulker_box.png");
    public ShulkerBoxPink = require("@/assets/images/spritecraft/blocks/pink_shulker_box.png");
    public ShulkerBoxPurple = require("@/assets/images/spritecraft/blocks/purple_shulker_box.png");
    public ShulkerBoxRed = require("@/assets/images/spritecraft/blocks/red_shulker_box.png");
    public ShulkerBoxWhite = require("@/assets/images/spritecraft/blocks/white_shulker_box.png");
    public ShulkerBoxYellow = require("@/assets/images/spritecraft/blocks/yellow_shulker_box.png");
    // Ocean:
    public Prismarine = require("@/assets/images/spritecraft/blocks/AA_modified_prismarine.png");
    public PrismarineBricks = require("@/assets/images/spritecraft/blocks/prismarine_bricks.png");
    public DarkPrismarine = require("@/assets/images/spritecraft/blocks/dark_prismarine.png");
    public DriedKelpBlock = require("@/assets/images/spritecraft/blocks/dried_kelp_side.png");
    public DriedKelpBlockTop = require("@/assets/images/spritecraft/blocks/dried_kelp_top.png");
    public DriedKelpBlockBottom = require("@/assets/images/spritecraft/blocks/dried_kelp_bottom.png");
    public SeaLantern = require("@/assets/images/spritecraft/blocks/AA_modified_sea_lantern.png");
    public BrainCoralBlock = require("@/assets/images/spritecraft/blocks/brain_coral_block.png");
    public BubbleCoralBlock = require("@/assets/images/spritecraft/blocks/bubble_coral_block.png");
    public FireCoralBlock = require("@/assets/images/spritecraft/blocks/fire_coral_block.png");
    public HornCoralBlock = require("@/assets/images/spritecraft/blocks/horn_coral_block.png");
    public TubeCoralBlock = require("@/assets/images/spritecraft/blocks/tube_coral_block.png");
    public DeadBrainCoralBlock = require("@/assets/images/spritecraft/blocks/dead_brain_coral_block.png");
    public DeadBubbleCoralBlock = require("@/assets/images/spritecraft/blocks/dead_bubble_coral_block.png");
    public DeadFireCoralBlock = require("@/assets/images/spritecraft/blocks/dead_fire_coral_block.png");
    public DeadHornCoralBlock = require("@/assets/images/spritecraft/blocks/dead_horn_coral_block.png");
    public DeadTubeCoralBlock = require("@/assets/images/spritecraft/blocks/dead_tube_coral_block.png");
    // Creative Only:
    public Bedrock = require("@/assets/images/spritecraft/blocks/bedrock.png");
    public Sponge = require("@/assets/images/spritecraft/blocks/sponge.png");
    public WetSponge = require("@/assets/images/spritecraft/blocks/wet_sponge.png");
    public BuddingAmethyst = require("@/assets/images/spritecraft/blocks/budding_amethyst.png");
    // Speed Cube:
    public SpeedCubeWhite = require("@/assets/images/spritecraft/blocks/speedcube_white.png");
    public SpeedCubeYellow = require("@/assets/images/spritecraft/blocks/speedcube_yellow.png");
    public SpeedCubeBlue = require("@/assets/images/spritecraft/blocks/speedcube_blue.png");
    public SpeedCubeGreen = require("@/assets/images/spritecraft/blocks/speedcube_green.png");
    public SpeedCubeRed = require("@/assets/images/spritecraft/blocks/speedcube_red.png");
    public SpeedCubeOrange = require("@/assets/images/spritecraft/blocks/speedcube_orange.png");
}   

