import { Block } from '@/logic/Spritecraft/Block';

export class BlocksForCategory {
    public name: string;
    public blocks: Block[];

	public constructor(name: string, blocks: Block[]) {
        this.name = name;
        this.blocks = blocks;
	}

    public get someActive(): boolean {
        // If some but not all are active, return true
        if (this.allActive) {
            return false;
        }
        for (const block of this.blocks) {
            if (block.isActive) {
                return true;
            }
        }
        return false;
    }

    public get allActive(): boolean {
        for (const block of this.blocks) {
            if (!block.isActive) {
                return false;
            }
        }
        return true;
    }

    public set allActive(value: boolean) {
        for (const block of this.blocks) {
            block.isActive = value;
        }
    }
}