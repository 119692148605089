import { BlockLoader } from "./BlockLoader";
import { Block } from "./Block";
import { Dithererer } from "./Dithererer";
import { LoadFinishedCallback } from "./LoadFinishedCallback";
import { ImageUtils } from '@/logic/Shared/ImageUtils';
import { Settings } from "./Settings";
import { DitherMode } from "./DitherMode";

interface ImageLoadCallback {
	(image: HTMLImageElement | null, errorMessage: string | null): void;
}

export class ImageProcessor {
	public settings: Settings;

	private blockLoader!: BlockLoader;
	private origImage: HTMLImageElement | null = null;

	private NATURAL_BLOCK_WIDTH = 16;

	// Max input image size so that it doesn't take 60 seconds or something terrible:
	// (this could potentially be quite a bit higher if we can get a decent progress bar somehow, and maybe a way to cancel)
	// (this can also be quite a bit higher if people are just okay with it--like if they know 2000x2000 could take 30 seconds maybe that's okay with them)
	private ABSOLUTE_MAX_INPUT_IMAGE_WIDTH = 500;
	private ABSOLUTE_MAX_INPUT_IMAGE_HEIGHT = 500;

	// Max output image size so that we don't crash:
	private ABSOLUTE_MAX_OUTPUT_IMAGE_WIDTH = 10000;
	private ABSOLUTE_MAX_OUTPUT_IMAGE_HEIGHT = 10000;

	public constructor(settings: Settings, loadFinishedCallback: LoadFinishedCallback) {
		this.settings = settings;
		this.blockLoader = new BlockLoader(loadFinishedCallback);
	}

	public setImage(origImage: HTMLImageElement) {
		this.origImage = origImage;
	}

	private getMaxInputWidth() {
		return this.settings.userMaxImageWidth > 0 ? Math.min(this.ABSOLUTE_MAX_INPUT_IMAGE_WIDTH, this.settings.userMaxImageWidth) : this.ABSOLUTE_MAX_INPUT_IMAGE_WIDTH;
	}

	private getMaxInputHeight() {
		return this.settings.userMaxImageHeight > 0 ? Math.min(this.ABSOLUTE_MAX_INPUT_IMAGE_HEIGHT, this.settings.userMaxImageHeight) : this.ABSOLUTE_MAX_INPUT_IMAGE_HEIGHT;
	}

	public processImage(imageLoadCallback: ImageLoadCallback) {
		if (this.origImage === null) {
			return;
		}

		const origImageData = ImageUtils.convertImageToImageData(this.origImage, this.getMaxInputWidth(), this.getMaxInputHeight());

		const minecraftImageData = this.getMinecraftImage(origImageData);

		const newImage = new Image();
		const outer = this;
		newImage.onload = function () { outer.imageLoadSuccess(newImage, imageLoadCallback); };
		newImage.onerror = function () { outer.imageLoadFail(imageLoadCallback); };
		newImage.src = ImageUtils.convertImageDataToImageSrc(minecraftImageData);
	}

	public getAllBlocks(): Block[] {
		return this.blockLoader.getAllBlocks();
	}

	private imageLoadSuccess(image: HTMLImageElement, imageLoadCallback: ImageLoadCallback) {
		imageLoadCallback(image, null);
	}

	private imageLoadFail(imageLoadCallback: ImageLoadCallback) {
		imageLoadCallback(null, "IT DIDN'T WORK! OH NO! WE'RE ALL DOOMED! DOOMED, I SAY! DOOOOOOOMED!");
	}

	private getMinecraftImage(origImageData: ImageData): ImageData {
		const blockSize = this.getBlockSizeToUse(origImageData);
		const minecraftImageData = new ImageData(origImageData.width * blockSize, origImageData.height * blockSize);
		const dithererer = new Dithererer(this.doDither(origImageData), origImageData.width);

		for (let y = 0; y < origImageData.height; y++) {
			for (let x = 0; x < origImageData.width; x++) {
				this.writeOneBlock(minecraftImageData, origImageData, dithererer, x, y, blockSize);
			}
			dithererer.newRow();
		}

		return minecraftImageData;
	}

	private doDither(origImageData: ImageData) {
		if (this.settings.ditherMode === DitherMode.Off) {
			return false;
		} else if (this.settings.ditherMode === DitherMode.On) {
			return true;
		} else { //(this.settings.ditherMode === DitherMode.Auto)
			return origImageData.width >= 100;
		}
	}

	private writeOneBlock(minecraftImageData: ImageData, origImageData: ImageData, dithererer: Dithererer, x: number, y: number, blockSize: number) {
		let pixel = ImageUtils.getImageDataPixelValue(origImageData, x, y);
		pixel = dithererer.adjustPixel(pixel, x);

		if (pixel.alpha > this.settings.minTransparency) {
			const blockFace = this.blockLoader.findBestMatch(pixel, this.settings.blockOrientationMode)!;
			dithererer.accountForDiff(pixel, blockFace.averageColor, x);
			ImageUtils.copyImageDataToImageData(blockFace.getImageData(blockSize), minecraftImageData, x * blockSize, y * blockSize);
		}
	}

	private getBlockSizeToUse(origImageData: ImageData): number
	{
		const afterRenderWidth = origImageData.width * this.NATURAL_BLOCK_WIDTH;
		const afterRenderHeight = origImageData.height * this.NATURAL_BLOCK_WIDTH;
		// If the image is too large, scale down the block sizes so we can handle it:
		let scale = Math.min(this.ABSOLUTE_MAX_OUTPUT_IMAGE_WIDTH / afterRenderWidth, this.ABSOLUTE_MAX_OUTPUT_IMAGE_HEIGHT / afterRenderHeight);

		// If the image isn't too large, set scale to 1 (we only shrink it, we don't enlarge it)
		scale = scale > 1 ? 1 : scale;
		return Math.floor(this.NATURAL_BLOCK_WIDTH * scale);
	}
}