import { Pixel } from '@/logic/Shared/Pixel';
import { Block } from "./Block";
import { BlockType } from "./BlockType";
import { BlockCategory } from "./BlockCategory";
import { BlockImages } from "./BlockImages";
import { BlockFace } from "./BlockFace";
import { BlockOrientationMode } from "./BlockOrientationMode";
import { LoadFinishedCallback } from "./LoadFinishedCallback";

export class BlockLoader {
	private blocks!: Block[];
	private numImagesToLoad: number;
	private numImagesLoaded: number;
	private loadFinishedCallback: LoadFinishedCallback | null;
	private blockImages = new BlockImages();

	public constructor(loadFinishedCallback: LoadFinishedCallback | null) {
		this.loadFinishedCallback = loadFinishedCallback;
		this.numImagesLoaded = 0;
		this.numImagesToLoad = 0;
		this.loadAllBlocks();
	}

	private loadAllBlocks(): void {
		this.blocks = [
			// // Speed Cube:
			// this.loadBlock(true, BlockType.SpeedCubeWhite, BlockCategory.SpeedCube, "White", [this.blockImages.SpeedCubeWhite]),
			// this.loadBlock(true, BlockType.SpeedCubeYellow, BlockCategory.SpeedCube, "Yellow", [this.blockImages.SpeedCubeYellow]),
			// this.loadBlock(true, BlockType.SpeedCubeBlue, BlockCategory.SpeedCube, "Blue", [this.blockImages.SpeedCubeBlue]),
			// this.loadBlock(true, BlockType.SpeedCubeGreen, BlockCategory.SpeedCube, "Green", [this.blockImages.SpeedCubeGreen]),
			// this.loadBlock(true, BlockType.SpeedCubeRed, BlockCategory.SpeedCube, "Red", [this.blockImages.SpeedCubeRed]),
			// this.loadBlock(true, BlockType.SpeedCubeOrange, BlockCategory.SpeedCube, "Orange", [this.blockImages.SpeedCubeOrange]),
			// Wool:
			this.loadBlock(true, BlockType.WhiteWool, BlockCategory.Wool, "White Wool", [this.blockImages.WhiteWool]),
			this.loadBlock(true, BlockType.LightGrayWool, BlockCategory.Wool, "Light Gray Wool", [this.blockImages.LightGrayWool]),
			this.loadBlock(true, BlockType.OrangeWool, BlockCategory.Wool, "Orange Wool", [this.blockImages.OrangeWool]),
			this.loadBlock(true, BlockType.CyanWool, BlockCategory.Wool, "Cyan Wool", [this.blockImages.CyanWool]),
			this.loadBlock(true, BlockType.MagentaWool, BlockCategory.Wool, "Magenta Wool", [this.blockImages.MagentaWool]),
			this.loadBlock(true, BlockType.PurpleWool, BlockCategory.Wool, "Purple Wool", [this.blockImages.PurpleWool]),
			this.loadBlock(true, BlockType.LightBlueWool, BlockCategory.Wool, "Light Blue Wool", [this.blockImages.LightBlueWool]),
			this.loadBlock(true, BlockType.BlueWool, BlockCategory.Wool, "Blue Wool", [this.blockImages.BlueWool]),
			this.loadBlock(true, BlockType.YellowWool, BlockCategory.Wool, "Yellow Wool", [this.blockImages.YellowWool]),
			this.loadBlock(true, BlockType.BrownWool, BlockCategory.Wool, "Brown Wool", [this.blockImages.BrownWool]),
			this.loadBlock(true, BlockType.LimeWool, BlockCategory.Wool, "Lime Wool", [this.blockImages.LimeWool]),
			this.loadBlock(true, BlockType.GreenWool, BlockCategory.Wool, "Green Wool", [this.blockImages.GreenWool]),
			this.loadBlock(true, BlockType.PinkWool, BlockCategory.Wool, "Pink Wool", [this.blockImages.PinkWool]),
			this.loadBlock(true, BlockType.RedWool, BlockCategory.Wool, "Red Wool", [this.blockImages.RedWool]),
			this.loadBlock(true, BlockType.GrayWool, BlockCategory.Wool, "Gray Wool", [this.blockImages.GrayWool]),
			this.loadBlock(true, BlockType.BlackWool, BlockCategory.Wool, "Black Wool", [this.blockImages.BlackWool]),
			// Wood:
			this.loadBlock(false, BlockType.OakWood, BlockCategory.Wood, "Oak Wood", [this.blockImages.OakWood, this.blockImages.OakWoodTop]),
			this.loadBlock(false, BlockType.StrippedOakWood, BlockCategory.Wood, "Stripped Oak Wood", [this.blockImages.StrippedOakWood, this.blockImages.StrippedOakWoodTop]),
			this.loadBlock(false, BlockType.OakPlanks, BlockCategory.Wood, "Oak Planks", [this.blockImages.OakPlanks]),
			this.loadBlock(false, BlockType.SpruceWood, BlockCategory.Wood, "Spruce Wood", [this.blockImages.SpruceWood, this.blockImages.SpruceWoodTop]),
			this.loadBlock(false, BlockType.StrippedSpruceWood, BlockCategory.Wood, "Stripped Spruce Wood", [this.blockImages.StrippedSpruceWood, this.blockImages.StrippedSpruceWoodTop]),
			this.loadBlock(false, BlockType.SprucePlanks, BlockCategory.Wood, "Spruce Planks", [this.blockImages.SprucePlanks]),
			this.loadBlock(false, BlockType.BirchWood, BlockCategory.Wood, "Birch Wood", [this.blockImages.BirchWood, this.blockImages.BirchWood]),
			this.loadBlock(false, BlockType.StrippedBirchWood, BlockCategory.Wood, "Stripped Birch Wood", [this.blockImages.StrippedBirchWood, this.blockImages.StrippedBirchWoodTop]),
			this.loadBlock(false, BlockType.BirchPlanks, BlockCategory.Wood, "Birch Planks", [this.blockImages.BirchPlanks]),
			this.loadBlock(false, BlockType.JungleWood, BlockCategory.Wood, "Jungle Wood", [this.blockImages.JungleWood, this.blockImages.JungleWoodTop]),
			this.loadBlock(false, BlockType.StrippedJungleWood, BlockCategory.Wood, "Stripped Jungle Wood", [this.blockImages.StrippedJungleWood, this.blockImages.StrippedJungleWoodTop]),
			this.loadBlock(false, BlockType.JunglePlanks, BlockCategory.Wood, "Jungle Planks", [this.blockImages.JunglePlanks]),
			this.loadBlock(false, BlockType.AcaciaWood, BlockCategory.Wood, "Acacia Wood", [this.blockImages.AcaciaWood, this.blockImages.AcaciaWoodTop]),
			this.loadBlock(false, BlockType.StrippedAcaciaWood, BlockCategory.Wood, "Stripped Acacia Wood", [this.blockImages.StrippedAcaciaWood, this.blockImages.StrippedAcaciaWoodTop]),
			this.loadBlock(false, BlockType.AcaciaPlanks, BlockCategory.Wood, "Acacia Planks", [this.blockImages.AcaciaPlanks]),
			this.loadBlock(false, BlockType.DarkOakWood, BlockCategory.Wood, "Dark Oak Wood", [this.blockImages.DarkOakWood, this.blockImages.DarkOakWoodTop]),
			this.loadBlock(false, BlockType.StrippedDarkOakWood, BlockCategory.Wood, "Stripped Dark Oak Wood", [this.blockImages.StrippedDarkOakWood, this.blockImages.StrippedDarkOakWoodTop]),
			this.loadBlock(false, BlockType.DarkOakPlanks, BlockCategory.Wood, "Dark Oak Planks", [this.blockImages.DarkOakPlanks]),
			this.loadBlock(false, BlockType.CrimsonHyphaeWood, BlockCategory.Wood, "Crimson Hyphae Wood", [this.blockImages.CrimsonHyphaeWood, this.blockImages.CrimsonHyphaeWoodTop]),
			this.loadBlock(false, BlockType.StrippedCrimsonHyphaeWood, BlockCategory.Wood, "Stripped Crimson Hyphae Wood", [this.blockImages.StrippedCrimsonHyphaeWood, this.blockImages.StrippedCrimsonHyphaeWoodTop]),
			this.loadBlock(false, BlockType.CrimsonPlanks, BlockCategory.Wood, "Crimson Planks", [this.blockImages.CrimsonPlanks]),
			this.loadBlock(false, BlockType.WarpedHyphaeWood, BlockCategory.Wood, "Warped Hyphae Wood", [this.blockImages.WarpedHyphaeWood, this.blockImages.WarpedHyphaeWoodTop]),
			this.loadBlock(false, BlockType.StrippedWarpedHyphaeWood, BlockCategory.Wood, "Stripped Warped Hyphae Wood", [this.blockImages.StrippedWarpedHyphaeWood, this.blockImages.StrippedWarpedHyphaeWoodTop]),
			this.loadBlock(false, BlockType.WarpedPlanks, BlockCategory.Wood, "Warped Planks", [this.blockImages.WarpedPlanks]),
			// Stone:
			this.loadBlock(false, BlockType.Stone, BlockCategory.Stone, "Stone", [this.blockImages.Stone]),
			this.loadBlock(false, BlockType.Cobblestone, BlockCategory.Stone, "Cobblestone", [this.blockImages.Cobblestone]),
			this.loadBlock(false, BlockType.MossyCobblestone, BlockCategory.Stone, "Mossy Cobblestone", [this.blockImages.MossyCobblestone]),
			this.loadBlock(false, BlockType.SmoothStone, BlockCategory.Stone, "Smooth Stone", [this.blockImages.SmoothStone]),
			this.loadBlock(false, BlockType.StoneBricks, BlockCategory.Stone, "Stone Bricks", [this.blockImages.StoneBricks]),
			this.loadBlock(false, BlockType.MossyStoneBricks, BlockCategory.Stone, "Mossy Stone Bricks", [this.blockImages.MossyStoneBricks]),
			this.loadBlock(false, BlockType.ChiseledStoneBricks, BlockCategory.Stone, "Chiseled Stone Bricks", [this.blockImages.ChiseledStoneBricks]),
			this.loadBlock(false, BlockType.CrackedStoneBricks, BlockCategory.Stone, "Cracked Stone Bricks", [this.blockImages.CrackedStoneBricks]),
			this.loadBlock(false, BlockType.Andesite, BlockCategory.Stone, "Andesite", [this.blockImages.Andesite]),
			this.loadBlock(false, BlockType.PolishedAndesite, BlockCategory.Stone, "Polished Andesite", [this.blockImages.PolishedAndesite]),
			this.loadBlock(false, BlockType.Diorite, BlockCategory.Stone, "Diorite", [this.blockImages.Diorite]),
			this.loadBlock(false, BlockType.PolishedDiorite, BlockCategory.Stone, "Polished Diorite", [this.blockImages.PolishedDiorite]),
			this.loadBlock(false, BlockType.Granite, BlockCategory.Stone, "Granite", [this.blockImages.Granite]),
			this.loadBlock(false, BlockType.PolishedGranite, BlockCategory.Stone, "Polished Granite", [this.blockImages.PolishedGranite]),
			this.loadBlock(false, BlockType.Deepslate, BlockCategory.Stone, "Deepslate", [this.blockImages.Deepslate]),
			this.loadBlock(false, BlockType.CobbledDeepslate, BlockCategory.Stone, "Cobbled Deepslate", [this.blockImages.CobbledDeepslate]),
			this.loadBlock(false, BlockType.DeepslateTiles, BlockCategory.Stone, "Deepslate Tiles", [this.blockImages.DeepslateTiles]),
			this.loadBlock(false, BlockType.CrackedDeepslateTiles, BlockCategory.Stone, "Cracked Deepslate Tiles", [this.blockImages.CrackedDeepslateTiles]),
			this.loadBlock(false, BlockType.ChiseledDeepslate, BlockCategory.Stone, "Chiseled Deepslate", [this.blockImages.ChiseledDeepslate]),
			this.loadBlock(false, BlockType.PolishedDeepslate, BlockCategory.Stone, "Polished Deepslate", [this.blockImages.PolishedDeepslate]),
			this.loadBlock(false, BlockType.DeepslateBricks, BlockCategory.Stone, "Deepslate Bricks", [this.blockImages.DeepslateBricks]),
			this.loadBlock(false, BlockType.CrackedDeepslateBricks, BlockCategory.Stone, "Cracked Deepslate Bricks", [this.blockImages.CrackedDeepslateBricks]),
			this.loadBlock(false, BlockType.Dripstone, BlockCategory.Stone, "Dripstone", [this.blockImages.Dripstone]),
			// Ores 'n such:
			this.loadBlock(false, BlockType.IronBlock, BlockCategory.Ore, "Iron Block", [this.blockImages.IronBlock]),
			this.loadBlock(false, BlockType.IronOre, BlockCategory.Ore, "Iron Ore", [this.blockImages.IronOre]),
			this.loadBlock(false, BlockType.DeepslateIronOre, BlockCategory.Ore, "Deepslate Iron Ore", [this.blockImages.DeepslateIronOre]),
			this.loadBlock(false, BlockType.GoldBlock, BlockCategory.Ore, "Gold Block", [this.blockImages.GoldBlock]),
			this.loadBlock(false, BlockType.RawGoldBlock, BlockCategory.Ore, "Raw Gold Block", [this.blockImages.RawGold]),
			this.loadBlock(false, BlockType.GoldOre, BlockCategory.Ore, "Gold Ore", [this.blockImages.GoldOre]),
			this.loadBlock(false, BlockType.DeepslateGoldOre, BlockCategory.Ore, "Deepslate Gold Ore", [this.blockImages.DeepslateGoldOre]),
			this.loadBlock(false, BlockType.DiamondBlock, BlockCategory.Ore, "Diamond Block", [this.blockImages.DiamondBlock]),
			this.loadBlock(false, BlockType.DiamondOre, BlockCategory.Ore, "Diamond Ore", [this.blockImages.DiamondOre]),
			this.loadBlock(false, BlockType.DeepslateDiamondOre, BlockCategory.Ore, "Deepslate Diamond Ore", [this.blockImages.DeepslateDiamondOre]),
			this.loadBlock(false, BlockType.CoalBlock, BlockCategory.Ore, "Coal Block", [this.blockImages.CoalBlock]),
			this.loadBlock(false, BlockType.CoalOre, BlockCategory.Ore, "Coal Ore", [this.blockImages.CoalOre]),
			this.loadBlock(false, BlockType.DeepslateCoalOre, BlockCategory.Ore, "Deepslate Coal Ore", [this.blockImages.DeepslateCoalOre]),
			this.loadBlock(false, BlockType.EmeraldBlock, BlockCategory.Ore, "Emerald Block", [this.blockImages.EmeraldBlock]),
			this.loadBlock(false, BlockType.EmeraldOre, BlockCategory.Ore, "Emerald Ore", [this.blockImages.EmeraldOre]),
			this.loadBlock(false, BlockType.DeepslateEmeraldOre, BlockCategory.Ore, "Deepslate Emerald Ore", [this.blockImages.DeepslateEmeraldOre]),
			this.loadBlock(false, BlockType.LapisLazuliBlock, BlockCategory.Ore, "Lapis Lazuli Block", [this.blockImages.LapisBlock]),
			this.loadBlock(false, BlockType.LapisLazuliOre, BlockCategory.Ore, "Lapis Lazuli Ore", [this.blockImages.LapisOre]),
			this.loadBlock(false, BlockType.DeepslateLapisLazuliOre, BlockCategory.Ore, "Deepslate Lapis Lazuli Ore", [this.blockImages.DeepslateLapisOre]),
			this.loadBlock(false, BlockType.RedstoneBlock, BlockCategory.Ore, "Redstone Block", [this.blockImages.RedstoneBlock]),
			this.loadBlock(false, BlockType.RedstoneOre, BlockCategory.Ore, "Redstone Ore", [this.blockImages.RedstoneOre]),
			this.loadBlock(false, BlockType.DeepslateRedstoneOre, BlockCategory.Ore, "Deepslate Redstone Ore", [this.blockImages.DeepslateRedstoneOre]),
			this.loadBlock(false, BlockType.CopperBlock, BlockCategory.Ore, "Copper Block", [this.blockImages.CopperBlock]),
			this.loadBlock(false, BlockType.CutCopperBlock, BlockCategory.Ore, "Cut Copper Block", [this.blockImages.CutCopperBlock]),
			this.loadBlock(false, BlockType.CopperOre, BlockCategory.Ore, "Copper Ore", [this.blockImages.CopperOre]),
			this.loadBlock(false, BlockType.DeepslateCopperOre, BlockCategory.Ore, "Deepslate Copper Ore", [this.blockImages.DeepslateCopperOre]),
			this.loadBlock(false, BlockType.RawCopperBlock, BlockCategory.Ore, "Raw Copper Block", [this.blockImages.RawCopper]),
			this.loadBlock(false, BlockType.AmethystBlock, BlockCategory.Ore, "Amethyst Block", [this.blockImages.AmethystBlock]),
			// Clay:
			this.loadBlock(false, BlockType.Clay, BlockCategory.Clay, "Clay", [this.blockImages.Clay]),
			this.loadBlock(false, BlockType.Bricks, BlockCategory.Clay, "Bricks", [this.blockImages.Bricks]),
			this.loadBlock(false, BlockType.Terracotta, BlockCategory.Clay, "Terracotta", [this.blockImages.Terracotta]),
			this.loadBlock(false, BlockType.WhiteTerracotta, BlockCategory.Clay, "White Terracotta", [this.blockImages.TerracottaWhite]),
			this.loadBlock(false, BlockType.OrangeTerracotta, BlockCategory.Clay, "Orange Terracotta", [this.blockImages.TerracottaOrange]),
			this.loadBlock(false, BlockType.MagentaTerracotta, BlockCategory.Clay, "Magenta Terracotta", [this.blockImages.TerracottaMagenta]),
			this.loadBlock(false, BlockType.LightBlueTerracotta, BlockCategory.Clay, "Light Blue Terracotta", [this.blockImages.TerracottaLightBlue]),
			this.loadBlock(false, BlockType.YellowTerracotta, BlockCategory.Clay, "Yellow Terracotta", [this.blockImages.TerracottaYellow]),
			this.loadBlock(false, BlockType.LimeTerracotta, BlockCategory.Clay, "Lime Terracotta", [this.blockImages.TerracottaLime]),
			this.loadBlock(false, BlockType.PinkTerracotta, BlockCategory.Clay, "Pink Terracotta", [this.blockImages.TerracottaPink]),
			this.loadBlock(false, BlockType.GrayTerracotta, BlockCategory.Clay, "Gray Terracotta", [this.blockImages.TerracottaGray]),
			this.loadBlock(false, BlockType.LightGrayTerracotta, BlockCategory.Clay, "Light Gray Terracotta", [this.blockImages.TerracottaLightGray]),
			this.loadBlock(false, BlockType.CyanTerracotta, BlockCategory.Clay, "Cyan Terracotta", [this.blockImages.TerracottaCyan]),
			this.loadBlock(false, BlockType.PurpleTerracotta, BlockCategory.Clay, "Purple Terracotta", [this.blockImages.TerracottaPurple]),
			this.loadBlock(false, BlockType.BlueTerracotta, BlockCategory.Clay, "Blue Terracotta", [this.blockImages.TerracottaBlue]),
			this.loadBlock(false, BlockType.BrownTerracotta, BlockCategory.Clay, "Brown Terracotta", [this.blockImages.TerracottaBrown]),
			this.loadBlock(false, BlockType.GreenTerracotta, BlockCategory.Clay, "Green Terracotta", [this.blockImages.TerracottaGreen]),
			this.loadBlock(false, BlockType.RedTerracotta, BlockCategory.Clay, "Red Terracotta", [this.blockImages.TerracottaRed]),
			this.loadBlock(false, BlockType.BlackTerracotta, BlockCategory.Clay, "Black Terracotta", [this.blockImages.TerracottaBlack]),
			this.loadBlock(false, BlockType.WhiteGlazedTerracotta, BlockCategory.Clay, "White Glazed Terracotta", [this.blockImages.TerracottaWhiteGlazed]),
			this.loadBlock(false, BlockType.OrangeGlazedTerracotta, BlockCategory.Clay, "Orange Glazed Terracotta", [this.blockImages.TerracottaOrangeGlazed]),
			this.loadBlock(false, BlockType.MagentaGlazedTerracotta, BlockCategory.Clay, "Magenta Glazed Terracotta", [this.blockImages.TerracottaMagentaGlazed]),
			this.loadBlock(false, BlockType.LightBlueGlazedTerracotta, BlockCategory.Clay, "Light Blue Glazed Terracotta", [this.blockImages.TerracottaLightBlueGlazed]),
			this.loadBlock(false, BlockType.YellowGlazedTerracotta, BlockCategory.Clay, "Yellow Glazed Terracotta", [this.blockImages.TerracottaYellowGlazed]),
			this.loadBlock(false, BlockType.LimeGlazedTerracotta, BlockCategory.Clay, "Lime Glazed Terracotta", [this.blockImages.TerracottaLimeGlazed]),
			this.loadBlock(false, BlockType.PinkGlazedTerracotta, BlockCategory.Clay, "Pink Glazed Terracotta", [this.blockImages.TerracottaPinkGlazed]),
			this.loadBlock(false, BlockType.GrayGlazedTerracotta, BlockCategory.Clay, "Gray Glazed Terracotta", [this.blockImages.TerracottaGrayGlazed]),
			this.loadBlock(false, BlockType.LightGrayGlazedTerracotta, BlockCategory.Clay, "Light Gray Glazed Terracotta", [this.blockImages.TerracottaLightGrayGlazed]),
			this.loadBlock(false, BlockType.CyanGlazedTerracotta, BlockCategory.Clay, "Cyan Glazed Terracotta", [this.blockImages.TerracottaCyanGlazed]),
			this.loadBlock(false, BlockType.PurpleGlazedTerracotta, BlockCategory.Clay, "Purple Glazed Terracotta", [this.blockImages.TerracottaPurpleGlazed]),
			this.loadBlock(false, BlockType.BlueGlazedTerracotta, BlockCategory.Clay, "Blue Glazed Terracotta", [this.blockImages.TerracottaBlueGlazed]),
			this.loadBlock(false, BlockType.BrownGlazedTerracotta, BlockCategory.Clay, "Brown Glazed Terracotta", [this.blockImages.TerracottaBrownGlazed]),
			this.loadBlock(false, BlockType.GreenGlazedTerracotta, BlockCategory.Clay, "Green Glazed Terracotta", [this.blockImages.TerracottaGreenGlazed]),
			this.loadBlock(false, BlockType.RedGlazedTerracotta, BlockCategory.Clay, "Red Glazed Terracotta", [this.blockImages.TerracottaRedGlazed]),
			this.loadBlock(false, BlockType.BlackGlazedTerracotta, BlockCategory.Clay, "Black Glazed Terracotta", [this.blockImages.TerracottaBlackGlazed]),
			// Concrete:
			this.loadBlock(false, BlockType.ConcreteBlack, BlockCategory.Concrete, "Black Concrete", [this.blockImages.ConcreteBlack]),
			this.loadBlock(false, BlockType.ConcreteBlue, BlockCategory.Concrete, "Blue Concrete", [this.blockImages.ConcreteBlue]),
			this.loadBlock(false, BlockType.ConcreteBrown, BlockCategory.Concrete, "Brown Concrete", [this.blockImages.ConcreteBrown]),
			this.loadBlock(false, BlockType.ConcreteCyan, BlockCategory.Concrete, "Cyan Concrete", [this.blockImages.ConcreteCyan]),
			this.loadBlock(false, BlockType.ConcreteGray, BlockCategory.Concrete, "Gray Concrete", [this.blockImages.ConcreteGray]),
			this.loadBlock(false, BlockType.ConcreteGreen, BlockCategory.Concrete, "Green Concrete", [this.blockImages.ConcreteGreen]),
			this.loadBlock(false, BlockType.ConcreteLightBlue, BlockCategory.Concrete, "Light Blue Concrete", [this.blockImages.ConcreteLightBlue]),
			this.loadBlock(false, BlockType.ConcreteLightGray, BlockCategory.Concrete, "Light Gray Concrete", [this.blockImages.ConcreteLightGray]),
			this.loadBlock(false, BlockType.ConcreteLime, BlockCategory.Concrete, "Lime Concrete", [this.blockImages.ConcreteLime]),
			this.loadBlock(false, BlockType.ConcreteMagenta, BlockCategory.Concrete, "Magenta Concrete", [this.blockImages.ConcreteMagenta]),
			this.loadBlock(false, BlockType.ConcreteOrange, BlockCategory.Concrete, "Orange Concrete", [this.blockImages.ConcreteOrange]),
			this.loadBlock(false, BlockType.ConcretePink, BlockCategory.Concrete, "Pink Concrete", [this.blockImages.ConcretePink]),
			this.loadBlock(false, BlockType.ConcretePurple, BlockCategory.Concrete, "Purple Concrete", [this.blockImages.ConcretePurple]),
			this.loadBlock(false, BlockType.ConcreteRed, BlockCategory.Concrete, "Red Concrete", [this.blockImages.ConcreteRed]),
			this.loadBlock(false, BlockType.ConcreteWhite, BlockCategory.Concrete, "White Concrete", [this.blockImages.ConcreteWhite]),
			this.loadBlock(false, BlockType.ConcreteYellow, BlockCategory.Concrete, "Yellow Concrete", [this.blockImages.ConcreteYellow]),
			this.loadBlock(false, BlockType.ConcretePowderBlack, BlockCategory.Concrete, "Black Concrete Powder", [this.blockImages.ConcretePowderBlack]),
			this.loadBlock(false, BlockType.ConcretePowderBlue, BlockCategory.Concrete, "Blue Concrete Powder", [this.blockImages.ConcretePowderBlue]),
			this.loadBlock(false, BlockType.ConcretePowderBrown, BlockCategory.Concrete, "Brown Concrete Powder", [this.blockImages.ConcretePowderBrown]),
			this.loadBlock(false, BlockType.ConcretePowderCyan, BlockCategory.Concrete, "Cyan Concrete Powder", [this.blockImages.ConcretePowderCyan]),
			this.loadBlock(false, BlockType.ConcretePowderGray, BlockCategory.Concrete, "Gray Concrete Powder", [this.blockImages.ConcretePowderGray]),
			this.loadBlock(false, BlockType.ConcretePowderGreen, BlockCategory.Concrete, "Green Concrete Powder", [this.blockImages.ConcretePowderGreen]),
			this.loadBlock(false, BlockType.ConcretePowderLightBlue, BlockCategory.Concrete, "Light Blue Concrete Powder", [this.blockImages.ConcretePowderLightBlue]),
			this.loadBlock(false, BlockType.ConcretePowderLightGray, BlockCategory.Concrete, "Light Gray Concrete Powder", [this.blockImages.ConcretePowderLightGray]),
			this.loadBlock(false, BlockType.ConcretePowderLime, BlockCategory.Concrete, "Lime Concrete Powder", [this.blockImages.ConcretePowderLime]),
			this.loadBlock(false, BlockType.ConcretePowderMagenta, BlockCategory.Concrete, "Magenta Concrete Powder", [this.blockImages.ConcretePowderMagenta]),
			this.loadBlock(false, BlockType.ConcretePowderOrange, BlockCategory.Concrete, "Orange Concrete Powder", [this.blockImages.ConcretePowderOrange]),
			this.loadBlock(false, BlockType.ConcretePowderPink, BlockCategory.Concrete, "Pink Concrete Powder", [this.blockImages.ConcretePowderPink]),
			this.loadBlock(false, BlockType.ConcretePowderPurple, BlockCategory.Concrete, "Purple Concrete Powder", [this.blockImages.ConcretePowderPurple]),
			this.loadBlock(false, BlockType.ConcretePowderRed, BlockCategory.Concrete, "Red Concrete Powder", [this.blockImages.ConcretePowderRed]),
			this.loadBlock(false, BlockType.ConcretePowderWhite, BlockCategory.Concrete, "White Concrete Powder", [this.blockImages.ConcretePowderWhite]),
			this.loadBlock(false, BlockType.ConcretePowderYellow, BlockCategory.Concrete, "Yellow Concrete Powder", [this.blockImages.ConcretePowderYellow]),
			// Natural:
			this.loadBlock(false, BlockType.Dirt, BlockCategory.Natural, "Dirt", [this.blockImages.Dirt]),
			this.loadBlock(false, BlockType.CoarseDirt, BlockCategory.Natural, "Coarse Dirt", [this.blockImages.CoarseDirt]),
			this.loadBlock(false, BlockType.Grass, BlockCategory.Natural, "Grass", [this.blockImages.Grass], [this.blockImages.GrassTop]),
			this.loadBlock(false, BlockType.GrassPath, BlockCategory.Natural, "Grass Path", [this.blockImages.GrassPath], [this.blockImages.GrassPathTop]),
			this.loadBlock(false, BlockType.Farmland, BlockCategory.Natural, "Farmland", [this.blockImages.Farmland]),
			this.loadBlock(false, BlockType.Gravel, BlockCategory.Natural, "Gravel", [this.blockImages.Gravel]),
			this.loadBlock(false, BlockType.Ice, BlockCategory.Natural, "Ice", [this.blockImages.Ice]),
			this.loadBlock(false, BlockType.PackedIce, BlockCategory.Natural, "Packed Ice", [this.blockImages.PackedIce]),
			this.loadBlock(false, BlockType.BlueIce, BlockCategory.Natural, "Blue Ice", [this.blockImages.BlueIce]),
			this.loadBlock(false, BlockType.Pumpkin, BlockCategory.Natural, "Pumpkin", [this.blockImages.Pumpkin], [this.blockImages.PumpkinTop]),
			this.loadBlock(false, BlockType.CarvedPumpkin, BlockCategory.Natural, "Carved Pumpkin", [this.blockImages.CarvedPumpkin, this.blockImages.Pumpkin], [this.blockImages.PumpkinTop]),
			this.loadBlock(false, BlockType.Melon, BlockCategory.Natural, "Melon", [this.blockImages.Melon], [this.blockImages.MelonTop]),
			this.loadBlock(false, BlockType.BrownMushroom, BlockCategory.Natural, "Brown Mushroom", [this.blockImages.BrownMushroom]),
			this.loadBlock(false, BlockType.RedMushroom, BlockCategory.Natural, "Red Mushroom", [this.blockImages.RedMushroom]),
			this.loadBlock(false, BlockType.MushroomStem, BlockCategory.Natural, "Mushroom Stem", [this.blockImages.MushroomStem]),
			this.loadBlock(false, BlockType.Mycelium, BlockCategory.Natural, "Mycelium", [this.blockImages.Mycelium], [this.blockImages.MyceliumTop]),
			this.loadBlock(false, BlockType.Obsidian, BlockCategory.Natural, "Obsidian", [this.blockImages.Obsidian]),
			this.loadBlock(false, BlockType.CryingObsidian, BlockCategory.Natural, "Crying Obsidian", [this.blockImages.CryingObsidian]),
			this.loadBlock(false, BlockType.BeeNest, BlockCategory.Natural, "Bee Nest", [this.blockImages.BeeNestFrontHoney, this.blockImages.BeeNest, this.blockImages.BeeNestFront], [this.blockImages.BeeNestTop], [this.blockImages.BeeNestBottom]),
			this.loadBlock(false, BlockType.Beehive, BlockCategory.Natural, "Beehive", [this.blockImages.BeehiveFrontHoney, this.blockImages.Beehive, this.blockImages.BeehiveFront], [this.blockImages.BeehiveTop]),
			this.loadBlock(false, BlockType.HoneyBlock, BlockCategory.Natural, "Honey Block", [this.blockImages.HoneyBlock], [this.blockImages.HoneyBlockTop]),
			this.loadBlock(false, BlockType.HoneycombBlock, BlockCategory.Natural, "Honeycomb Block", [this.blockImages.HoneycombBlock]),
			this.loadBlock(false, BlockType.Calcite, BlockCategory.Natural, "Calcite", [this.blockImages.Calcite]),
			this.loadBlock(false, BlockType.MossBlock, BlockCategory.Natural, "Moss Block", [this.blockImages.MossBlock]),
			this.loadBlock(false, BlockType.Podzol, BlockCategory.Natural, "Podzol", [this.blockImages.Podzol], [this.blockImages.Podzol_Top]),
			this.loadBlock(false, BlockType.Snow, BlockCategory.Natural, "Snow", [this.blockImages.Snow]),
			this.loadBlock(false, BlockType.PowderSnow, BlockCategory.Natural, "Powder Snow", [this.blockImages.PowderedSnow]),
			this.loadBlock(false, BlockType.Shroomlight, BlockCategory.Natural, "Shroomlight", [this.blockImages.Shroomlight]),
			this.loadBlock(false, BlockType.BoneBlock, BlockCategory.Natural, "Bone Block", [this.blockImages.BoneBlock, this.blockImages.BoneBlockTop]),
			// Crafted:
			this.loadBlock(false, BlockType.Bookshelf, BlockCategory.Crafted, "Bookshelf", [this.blockImages.Bookshelf]),
			this.loadBlock(false, BlockType.CraftingTable, BlockCategory.Crafted, "Crafting Table", [this.blockImages.CraftingTableFront, this.blockImages.CraftingTableSide], [this.blockImages.CraftingTableTop]),
			this.loadBlock(false, BlockType.Loom, BlockCategory.Crafted, "Loom", [this.blockImages.Loom, this.blockImages.LoomSide], [this.blockImages.LoomTop], [this.blockImages.LoomBottom]),
			this.loadBlock(false, BlockType.CartographyTable, BlockCategory.Crafted, "Cartography Table", [this.blockImages.CartographyTable, this.blockImages.CartographyTableSide2, this.blockImages.CartographyTableSide3], [this.blockImages.CartographyTableTop]),
			this.loadBlock(false, BlockType.Furnace, BlockCategory.Crafted, "Furnace", [this.blockImages.Furnace, this.blockImages.FurnaceSide], [this.blockImages.FurnaceTop]),
			this.loadBlock(false, BlockType.BlastFurnace, BlockCategory.Crafted, "Blast Furnace", [this.blockImages.BlastFurnace, this.blockImages.BlastFurnaceSide], [this.blockImages.BlastFurnaceTop]),
			this.loadBlock(false, BlockType.Dispenser, BlockCategory.Crafted, "Dispenser", [this.blockImages.Dispenser]),
			this.loadBlock(false, BlockType.Dropper, BlockCategory.Crafted, "Dropper", [this.blockImages.Dropper]),
			this.loadBlock(false, BlockType.Lodestone, BlockCategory.Crafted, "Lodestone", [this.blockImages.Lodestone], [this.blockImages.LodestoneTop]),
			this.loadBlock(false, BlockType.FletchingTable, BlockCategory.Crafted, "Fletching Table", [this.blockImages.FletchingTable, this.blockImages.FletchingTableSide], [this.blockImages.FletchingTableTop]),
			this.loadBlock(false, BlockType.HayBale, BlockCategory.Crafted, "Hay Bale", [this.blockImages.HayBale], [this.blockImages.HayBaleTop]),
			this.loadBlock(false, BlockType.Target, BlockCategory.Crafted, "Target", [this.blockImages.Target], [this.blockImages.TargetTop]),
			this.loadBlock(false, BlockType.Jukebox, BlockCategory.Crafted, "Jukebox", [this.blockImages.Jukebox], [this.blockImages.JukeboxTop]),
			this.loadBlock(false, BlockType.NoteBlock, BlockCategory.Crafted, "Note Block", [this.blockImages.NoteBlock]),
			this.loadBlock(false, BlockType.Observer, BlockCategory.Crafted, "Observer", [this.blockImages.Observer, this.blockImages.ObserverSide, this.blockImages.ObserverBack], [this.blockImages.ObserverTop]),
			this.loadBlock(false, BlockType.Piston, BlockCategory.Crafted, "Piston", [this.blockImages.Piston],[this.blockImages.PistonTop], [this.blockImages.PistonBottom]),
			this.loadBlock(false, BlockType.RespawnAnchor, BlockCategory.Crafted, "Respawn Anchor", [this.blockImages.RespawnAnchor, this.blockImages.RespawnAnchorSide1, this.blockImages.RespawnAnchorSide2, this.blockImages.RespawnAnchorSide3, this.blockImages.RespawnAnchorSide4], [this.blockImages.RespawnAnchorTop], [this.blockImages.RespawnAnchorBottom]),
			this.loadBlock(false, BlockType.SlimeBlock, BlockCategory.Crafted, "Slime Block", [this.blockImages.SlimeBlock]),
			this.loadBlock(false, BlockType.SmithingTable, BlockCategory.Crafted, "Smithing Table", [this.blockImages.SmithingTable, this.blockImages.SmithingTableSide], [this.blockImages.SmithingTableTop], [this.blockImages.SmithingTableBottom]),
			this.loadBlock(false, BlockType.Smoker, BlockCategory.Crafted, "Smoker", [this.blockImages.Smoker, this.blockImages.SmokerSide], [this.blockImages.SmokerTop], [this.blockImages.SmokerBottom]),
			this.loadBlock(false, BlockType.TNT, BlockCategory.Crafted, "TNT", [this.blockImages.TNT], [this.blockImages.TNTTop], [this.blockImages.TNTBottom]),
			// Desert:
			this.loadBlock(false, BlockType.Sand, BlockCategory.Desert, "Sand", [this.blockImages.Sand]),
			this.loadBlock(false, BlockType.RedSand, BlockCategory.Desert, "Red Sand", [this.blockImages.RedSand]),
			this.loadBlock(false, BlockType.Sandstone, BlockCategory.Desert, "Sandstone", [this.blockImages.Sandstone],[this.blockImages.SandstoneTop],[this.blockImages.SandstoneBottom]),
			this.loadBlock(false, BlockType.SmoothSandstone, BlockCategory.Desert, "Smooth Sandstone", [this.blockImages.SandstoneTop]),
			this.loadBlock(false, BlockType.CutSandstone, BlockCategory.Desert, "Cut Sandstone", [this.blockImages.CutSandstone],[this.blockImages.SandstoneTop],[this.blockImages.SandstoneBottom]),
			this.loadBlock(false, BlockType.ChiseledSandstone, BlockCategory.Desert, "Chiseled Sandstone", [this.blockImages.ChiseledSandstone],[this.blockImages.SandstoneTop],[this.blockImages.SandstoneBottom]),
			this.loadBlock(false, BlockType.RedSandstone, BlockCategory.Desert, "Red Sandstone", [this.blockImages.RedSandstone],[this.blockImages.RedSandstoneTop],[this.blockImages.RedSandstoneBottom]),
			this.loadBlock(false, BlockType.SmoothRedSandstone, BlockCategory.Desert, "Smooth Red Sandstone", [this.blockImages.RedSandstoneTop]),
			this.loadBlock(false, BlockType.CutRedSandstone, BlockCategory.Desert, "Cut Red Sandstone", [this.blockImages.CutRedSandstone],[this.blockImages.RedSandstoneTop],[this.blockImages.RedSandstoneBottom]),
			this.loadBlock(false, BlockType.ChiseledRedSandstone, BlockCategory.Desert, "Chiseled Red Sandstone", [this.blockImages.ChiseledRedSandstone],[this.blockImages.RedSandstoneTop],[this.blockImages.RedSandstoneBottom]),
			//this.loadBlock(false, BlockType.Cactus, BlockCategory.Desert, "Cactus", [this.blockImages.Cactus],[this.blockImages.CactusTop],[this.blockImages.CactusBottom]),
			// Nether:
			this.loadBlock(false, BlockType.Netherrack, BlockCategory.Nether, "Netherrack", [this.blockImages.Netherrack]),
			this.loadBlock(false, BlockType.SoulSand, BlockCategory.Nether, "Soul Sand", [this.blockImages.SoulSand]),
			this.loadBlock(false, BlockType.SoulSoil, BlockCategory.Nether, "Soul Soil", [this.blockImages.SoulSoil]),
			this.loadBlock(false, BlockType.Glowstone, BlockCategory.Nether, "Glowstone", [this.blockImages.Glowstone]),
			this.loadBlock(false, BlockType.RedstoneLamp, BlockCategory.Nether, "Redstone Lamp", [this.blockImages.RedstoneLamp]),
			this.loadBlock(false, BlockType.MagmaBlock, BlockCategory.Nether, "Magma", [this.blockImages.MagmaBlock]),
			this.loadBlock(false, BlockType.NetherWartBlock, BlockCategory.Nether, "Nether Wart", [this.blockImages.NetherWartBlock]),
			this.loadBlock(false, BlockType.CrimsonNylium, BlockCategory.Nether, "Crimson Nylium", [this.blockImages.CrimsonNylium],[this.blockImages.CrimsonNyliumTop]),
			this.loadBlock(false, BlockType.WarpedNylium, BlockCategory.Nether, "Warped Nylium", [this.blockImages.WarpedNylium],[this.blockImages.WarpedNyliumTop]),
			this.loadBlock(false, BlockType.AncientDebris, BlockCategory.Nether, "Ancient Debris", [this.blockImages.AncientDebris],[this.blockImages.AncientDebrisTop]),
			this.loadBlock(false, BlockType.NetheriteBlock, BlockCategory.Nether, "Netherite", [this.blockImages.NetheriteBlock]),
			this.loadBlock(false, BlockType.NetherGoldOre, BlockCategory.Nether, "Nether Gold Ore", [this.blockImages.NetherGoldOre]),
			this.loadBlock(false, BlockType.NetherQuartzOre, BlockCategory.Nether, "Quartz Ore", [this.blockImages.NetherQuartzOre]),
			this.loadBlock(false, BlockType.QuartzBlock, BlockCategory.Nether, "Quartz Block", [this.blockImages.QuartzBlock],[this.blockImages.QuartzBlockTop],[this.blockImages.QuartzBlockBottom]),
			this.loadBlock(false, BlockType.QuartzBricks, BlockCategory.Nether, "Quartz Bricks", [this.blockImages.QuartzBricks]),
			this.loadBlock(false, BlockType.QuartzPillar, BlockCategory.Nether, "Quartz Pillar", [this.blockImages.QuartzPillar, this.blockImages.QuartzPillarTop]),
			this.loadBlock(false, BlockType.ChiseledQuartzBlock, BlockCategory.Nether, "Chiseled Quartz Block", [this.blockImages.ChiseledQuartzBlock],[this.blockImages.ChiseledQuartzBlockTop]),
			this.loadBlock(false, BlockType.Basalt, BlockCategory.Nether, "Basalt", [this.blockImages.Basalt, this.blockImages.BasaltTop]),
			this.loadBlock(false, BlockType.SmoothBasalt, BlockCategory.Nether, "Smooth Basalt", [this.blockImages.SmoothBasalt]),
			this.loadBlock(false, BlockType.PolishedBasalt, BlockCategory.Nether, "Polished Basalt", [this.blockImages.PolishedBasalt, this.blockImages.PolishedBasaltTop]),
			this.loadBlock(false, BlockType.NetherBricks, BlockCategory.Nether, "Nether Bricks", [this.blockImages.NetherBricks]),
			this.loadBlock(false, BlockType.RedNetherBricks, BlockCategory.Nether, "Red Nether Bricks", [this.blockImages.RedNetherBricks]),
			this.loadBlock(false, BlockType.CrackedNetherBricks, BlockCategory.Nether, "Cracked Nether Bricks", [this.blockImages.CrackedNetherBricks]),
			this.loadBlock(false, BlockType.ChiseledNetherBricks, BlockCategory.Nether, "Chiseled Nether Bricks", [this.blockImages.ChiseledNetherBricks]),
			this.loadBlock(false, BlockType.Blackstone, BlockCategory.Nether, "Blackstone", [this.blockImages.Blackstone],[this.blockImages.BlackstoneTop]),
			this.loadBlock(false, BlockType.GildedBlackstone, BlockCategory.Nether, "Gilded Blackstone", [this.blockImages.GildedBlackstone]),
			this.loadBlock(false, BlockType.PolishedBlackstone, BlockCategory.Nether, "Polished Blackstone", [this.blockImages.PolishedBlackstone]),
			this.loadBlock(false, BlockType.ChiseledPolishedBlackstone, BlockCategory.Nether, "Chiseled Polished Blackstone", [this.blockImages.ChiseledPolishedBlackstone]),
			this.loadBlock(false, BlockType.PolishedBlackstoneBricks, BlockCategory.Nether, "Polished Blackstone Bricks", [this.blockImages.PolishedBlackstoneBricks]),
			this.loadBlock(false, BlockType.CrackedPolishedBlackstoneBricks, BlockCategory.Nether, "Cracked Polished Blackstone Bricks", [this.blockImages.CrackedPolishedBlackstoneBricks]),
			// The End:
			this.loadBlock(false, BlockType.EndStone, BlockCategory.End, "End Stone", [this.blockImages.EndStone]),
			this.loadBlock(false, BlockType.EndStoneBricks, BlockCategory.End, "End Stone Bricks", [this.blockImages.EndStoneBricks]),
			this.loadBlock(false, BlockType.PurpurBlock, BlockCategory.End, "Purpur Block", [this.blockImages.PurpurBlock]),
			this.loadBlock(false, BlockType.PurpurPillar, BlockCategory.End, "Purpur Pillar", [this.blockImages.PurpurPillar, this.blockImages.PurpurPillarTop]),
			this.loadBlock(false, BlockType.ShulkerBox, BlockCategory.End, "Shulker Box", [this.blockImages.ShulkerBox]),
			this.loadBlock(false, BlockType.ShulkerBoxBlack, BlockCategory.End, "Black Shulker Box", [this.blockImages.ShulkerBoxBlack]),
			this.loadBlock(false, BlockType.ShulkerBoxBlue, BlockCategory.End, "Blue Shulker Box", [this.blockImages.ShulkerBoxBlue]),
			this.loadBlock(false, BlockType.ShulkerBoxBrown, BlockCategory.End, "Brown Shulker Box", [this.blockImages.ShulkerBoxBrown]),
			this.loadBlock(false, BlockType.ShulkerBoxCyan, BlockCategory.End, "Cyan Shulker Box", [this.blockImages.ShulkerBoxCyan]),
			this.loadBlock(false, BlockType.ShulkerBoxGray, BlockCategory.End, "Gray Shulker Box", [this.blockImages.ShulkerBoxGray]),
			this.loadBlock(false, BlockType.ShulkerBoxGreen, BlockCategory.End, "Green Shulker Box", [this.blockImages.ShulkerBoxGreen]),
			this.loadBlock(false, BlockType.ShulkerBoxLightBlue, BlockCategory.End, "Light Blue Shulker Box", [this.blockImages.ShulkerBoxLightBlue]),
			this.loadBlock(false, BlockType.ShulkerBoxLightGray, BlockCategory.End, "Light Gray Shulker Box", [this.blockImages.ShulkerBoxLightGray]),
			this.loadBlock(false, BlockType.ShulkerBoxLime, BlockCategory.End, "Lime Shulker Box", [this.blockImages.ShulkerBoxLime]),
			this.loadBlock(false, BlockType.ShulkerBoxMagenta, BlockCategory.End, "Magenta Shulker Box", [this.blockImages.ShulkerBoxMagenta]),
			this.loadBlock(false, BlockType.ShulkerBoxOrange, BlockCategory.End, "Orange Shulker Box", [this.blockImages.ShulkerBoxOrange]),
			this.loadBlock(false, BlockType.ShulkerBoxPink, BlockCategory.End, "Pink Shulker Box", [this.blockImages.ShulkerBoxPink]),
			this.loadBlock(false, BlockType.ShulkerBoxPurple, BlockCategory.End, "Purple Shulker Box", [this.blockImages.ShulkerBoxPurple]),
			this.loadBlock(false, BlockType.ShulkerBoxRed, BlockCategory.End, "Red Shulker Box", [this.blockImages.ShulkerBoxRed]),
			this.loadBlock(false, BlockType.ShulkerBoxWhite, BlockCategory.End, "White Shulker Box", [this.blockImages.ShulkerBoxWhite]),
			this.loadBlock(false, BlockType.ShulkerBoxYellow, BlockCategory.End, "Yellow Shulker Box", [this.blockImages.ShulkerBoxYellow]),
			// Ocean:
			this.loadBlock(false, BlockType.Prismarine, BlockCategory.Ocean, "Prismarine", [this.blockImages.Prismarine]),
			this.loadBlock(false, BlockType.PrismarineBricks, BlockCategory.Ocean, "Prismarine Bricks", [this.blockImages.PrismarineBricks]),
			this.loadBlock(false, BlockType.DarkPrismarine, BlockCategory.Ocean, "Dark Prismarine", [this.blockImages.DarkPrismarine]),
			this.loadBlock(false, BlockType.DriedKelpBlock, BlockCategory.Ocean, "Dried Kelp Block", [this.blockImages.DriedKelpBlock],[this.blockImages.DriedKelpBlockTop],[this.blockImages.DriedKelpBlockBottom]),
			this.loadBlock(false, BlockType.SeaLantern, BlockCategory.Ocean, "Sea Lantern", [this.blockImages.SeaLantern]),
			this.loadBlock(false, BlockType.BrainCoralBlock, BlockCategory.Ocean, "Brain Coral Block", [this.blockImages.BrainCoralBlock]),
			this.loadBlock(false, BlockType.BubbleCoralBlock, BlockCategory.Ocean, "Bubble Coral Block", [this.blockImages.BubbleCoralBlock]),
			this.loadBlock(false, BlockType.FireCoralBlock, BlockCategory.Ocean, "Fire Coral Block", [this.blockImages.FireCoralBlock]),
			this.loadBlock(false, BlockType.HornCoralBlock, BlockCategory.Ocean, "Horn Coral Block", [this.blockImages.HornCoralBlock]),
			this.loadBlock(false, BlockType.TubeCoralBlock, BlockCategory.Ocean, "Tube Coral Block", [this.blockImages.TubeCoralBlock]),
			this.loadBlock(false, BlockType.DeadBrainCoralBlock, BlockCategory.Ocean, "Dead Brain Coral Block", [this.blockImages.DeadBrainCoralBlock]),
			this.loadBlock(false, BlockType.DeadBubbleCoralBlock, BlockCategory.Ocean, "Dead Bubble Coral Block", [this.blockImages.DeadBubbleCoralBlock]),
			this.loadBlock(false, BlockType.DeadFireCoralBlock, BlockCategory.Ocean, "Dead Fire Coral Block", [this.blockImages.DeadFireCoralBlock]),
			this.loadBlock(false, BlockType.DeadHornCoralBlock, BlockCategory.Ocean, "Dead Horn Coral Block", [this.blockImages.DeadHornCoralBlock]),
			this.loadBlock(false, BlockType.DeadTubeCoralBlock, BlockCategory.Ocean, "Dead Tube Coral Block", [this.blockImages.DeadTubeCoralBlock]),
			// Creative:
			this.loadBlock(false, BlockType.Bedrock, BlockCategory.Creative, "Bedrock", [this.blockImages.Bedrock]),
			this.loadBlock(false, BlockType.Sponge, BlockCategory.Creative, "Sponge", [this.blockImages.Sponge]),
			this.loadBlock(false, BlockType.WetSponge, BlockCategory.Creative, "Wet Sponge", [this.blockImages.WetSponge]),
			this.loadBlock(false, BlockType.BuddingAmethyst, BlockCategory.Creative, "Budding Amethyst", [this.blockImages.BuddingAmethyst]),
			// 
			// -- Questionables --
			// Waxed Copper Blocks?
			// Carved Pumpkin?
			// Frosted Ice?
			// Glass (and all colors?)
			// Command Blocks?
			// Non-square blocks? (Chest, anvil, saw, etc)
			// Rooted Dirt?
			// Composter (+fills up?)
			// Sticky Piston?
			// Redstone Lamp On?
			// End Portal Frame?
		];
	}

	private loadBlock(isActiveByDefault: boolean, blockType: BlockType, blockCategory: BlockCategory, name: string, imageNames: string[], topImageNames: string[] | null = null, bottomImageNames: string[] | null = null): Block {
		this.numImagesToLoad += imageNames.length;
		this.numImagesToLoad += topImageNames ? topImageNames.length : 0;
		this.numImagesToLoad += bottomImageNames ? bottomImageNames.length : 0;

		let firstSideImageName = null;
		let firstTopImageName = null;
		let firstBottomImageName = null;

		const sideImages: HTMLImageElement[] = [];
		for (const imageName of imageNames) {
			sideImages.push(this.createImage(imageName))
			if (!firstSideImageName) { firstSideImageName = imageName; }
		}

		let topImages: HTMLImageElement[] = [];
		if (topImageNames && topImageNames.length > 0) {
			for (const imageName of topImageNames) {
				topImages.push(this.createImage(imageName))
				if (!firstTopImageName) { firstTopImageName = imageName; }
			}
		} else {
			topImages = sideImages;
			firstTopImageName = firstSideImageName;
		}

		let bottomImages: HTMLImageElement[] = [];
		if (bottomImageNames && bottomImageNames.length > 0) {
			for (const imageName of bottomImageNames) {
				bottomImages.push(this.createImage(imageName))
				if (!firstBottomImageName) { firstBottomImageName = imageName; }
			}
		} else {
			bottomImages = topImages.length > 0 ? topImages : sideImages;
			firstBottomImageName = topImages.length > 0 ? firstTopImageName : firstSideImageName;
		}

		return new Block(blockType, blockCategory, name, firstSideImageName!, firstTopImageName!, firstBottomImageName!, isActiveByDefault, sideImages, topImages, bottomImages);
	}

	private createImage(src: string): HTMLImageElement {
		const image = new Image();
		const outer = this;
		image.onload = function () { outer.imageLoadSuccess(); };
		image.onerror = function () { outer.imageLoadFail(src); };
		image.src = src;
		return image;
	}

	private imageLoadSuccess() {
		this.numImagesLoaded++;
		if (this.numImagesLoaded === this.numImagesToLoad) {
			if (this.loadFinishedCallback) {
				this.loadFinishedCallback(null);
			}
		}
	}

	private imageLoadFail(src: string) {
		if (this.loadFinishedCallback) {
			this.loadFinishedCallback("Image load failed for " + src);
		}
	}

	public findBestMatch(pixel: Pixel, mode: BlockOrientationMode): BlockFace | null {
		let bestDiff = Pixel.MAXDIFF;
		let bestFace: BlockFace | null = null;
		for (const block of this.blocks) {
			if (block.isActive) {
				const blockFaces = block.getFaces(mode);
				for (const blockFace of blockFaces) {
					const diff = pixel.diff(blockFace.averageColor);
					if (diff < bestDiff) {
						bestDiff = diff;
						bestFace = blockFace;
					}
				}
			}
		}
		return bestFace;
	}

	public getAllBlocks(): Block[] {
		return this.blocks;
	}
}