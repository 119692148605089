import { BlockOrientationMode } from './BlockOrientationMode';
import { DitherMode } from './DitherMode';

export class Settings {
    public userMaxImageWidth = 250;
    public userMaxImageHeight = 250;
    public ditherMode = DitherMode.Auto;
    public blockOrientationMode = BlockOrientationMode.Side;
    public minTransparency = 50; // Number from 0-255, represents a pixel's alpha value

    public copyFrom(otherSettings: Settings) {
        this.userMaxImageWidth = otherSettings.userMaxImageWidth;
        this.userMaxImageHeight = otherSettings.userMaxImageHeight;
        this.ditherMode = otherSettings.ditherMode;
        this.blockOrientationMode = otherSettings.blockOrientationMode;
        this.minTransparency = otherSettings.minTransparency;
    }

    public areSame(otherSettings: Settings): boolean {
        return this.userMaxImageWidth === otherSettings.userMaxImageWidth
            && this.userMaxImageHeight === otherSettings.userMaxImageHeight
            && this.ditherMode === otherSettings.ditherMode
            && this.blockOrientationMode === otherSettings.blockOrientationMode
            && this.minTransparency === otherSettings.minTransparency;
    }
}